import React from "react";
import { Table, Center, Anchor, Text } from "@mantine/core";
import { FormattedMessage } from "react-intl";

export default function NotEnoughResponses(props) {
  const { graphType, setGraphType, questionId, responseData, taggedData } = props;
  const taggedCount = taggedData === undefined ? 0 : taggedData.length;
  console.log("Tagged Data:", taggedData);
  return (
    <Table>
      <tbody>
        <tr>
          <td>
            {responseData && responseData.length > 10 ? (
              <Center>
                <Text color="dimmed">
                  {taggedCount} out of {responseData.length} analysed.
                  <Anchor onClick={() => setGraphType(new Map([...graphType, ...new Map([[questionId, "verbatim"]])]))}>
                    check the verbatim responses
                  </Anchor>
                  .
                </Text>
              </Center>
            ) : (
              <Center>
                <Text color="dimmed">
                  <FormattedMessage
                    id="reports.no_ai_analysis"
                    values={{
                      link: chunks => (
                        <Anchor
                          onClick={() => setGraphType(new Map([...graphType, ...new Map([[questionId, "verbatim"]])]))}
                        >
                          {chunks}
                        </Anchor>
                      ),
                    }}
                    defaultMessage={
                      "You need at least ten responses to build a pattern. Meanwhile, you can check the verbatim responses."
                    }
                  />
                </Text>
              </Center>
            )}
          </td>
        </tr>
      </tbody>
    </Table>
  );
}
