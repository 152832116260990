import React, { useState, useEffect } from "react";
import {
  Radio,
  Group,
  Text,
  Space,
  TextInput,
  NumberInput,
  Button,
  Divider,
  Accordion,
  Alert,
  Box,
} from "@mantine/core";
import { IconCircleCheck, IconAlertCircle } from "@tabler/icons-react";
import { pluralize, humanizedString } from "../../../../utils/helpers";
import { AttributeFilter } from "./AttributeFilter";
import { TextualTag, StyledSelect, SubHeadingText } from "../../../../components/MantineOverride";
// import { wrap } from '@sentry/react';

export function SurveySettings(props) {
  const { dispatch, formState, prodToken } = props;
  let options = formState.surveyOptions;

  const [accordionItem, setAccordionItem] = useState("platform");

  function handleAccordionChange(e) {
    console.log("According Change", e);
    setAccordionItem(e);
  }

  useEffect(() => {
    //this is effect is to synchronise formState update
    //Ref: https://samuelsson.dev/update-state-with-usereducer-synchronously/
  }, [formState]);

  function handleInputChange(value, section, field) {
    console.log("$123 value in radio group input is", value);
    dispatch({
      type: "settingChange",
      value: value,
      section: section,
      field: field,
    });
  }

  const selectedPages = () => {
    return parseInt(options.triggerCondition.pages) === "all" ? "All Pagess" : "Specific URL";
  };

  const selectedTrigger = () => {
    const condition = options.triggerCondition;
    console.log("## eventType", condition.eventType);
    switch (condition.eventType) {
      case "pageLoad":
        return "On Page Load";
      case "abandon":
        return "On leaving page";
      case "halfScroll":
        return "On page scroll";
      case "textClick":
      case "cssClick":
        return "On Click";
      default:
        break;
    }
  };

  const selectedDelay = () => {
    const condition = options.triggerCondition;
    switch (condition.waitFor) {
      case 0:
        return "Immediately";
      default:
        return `${condition.waitFor} seconds delay`;
    }
  };

  function addFilter(e) {
    e.preventDefault();
    dispatch({
      type: "addFilter",
    });
  }

  return (
    <>
      <Space h="md" />
      <Accordion
        variant="separated"
        onChange={e => handleAccordionChange(e)}
        radius="md"
        defaultValue="when-to-show"
        multiple
        styles={{
          item: {
            // styles added to all items
            backgroundColor: "#fff",
            border: "1px solid #ededed",

            // styles added to expanded item
            "&[data-active]": {
              backgroundColor: "#fff",
            },
          },

          chevron: {
            // styles added to chevron when it should rotate
            "&[data-rotate]": {
              transform: "rotate(-90deg)",
            },
          },
        }}
      >
        {formState.platform === "web" && (
          <>
            <Accordion.Item value="when-to-show">
              <Accordion.Control>
                <Group position="apart">
                  <div>
                    <Text size="md" weight={400}>
                      When to show
                    </Text>
                    <Text size="xs" color="dimmed">
                      Select pages and trigger.
                    </Text>
                  </div>
                  <Group>
                    {accordionItem === "when-to-show" ? null : <TextualTag>{selectedPages()}</TextualTag>}
                    {accordionItem === "when-to-show" ? null : (
                      <Group>
                        <TextualTag>{selectedTrigger()}</TextualTag>
                        <TextualTag>{selectedDelay()}</TextualTag>
                        <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                      </Group>
                    )}
                  </Group>
                </Group>
                {accordionItem === "when-to-show" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Box>
                  <SubHeadingText>Show on</SubHeadingText>

                  <Radio.Group
                    value={options.triggerCondition.pages}
                    onChange={e => handleInputChange(e, "triggerCondition", "pages")}
                    name="selectPages"
                    orientation="vertical"
                    spacing="xs"
                  >
                    <Radio value="all" label="All pages" />
                    <Radio value="specific" label="Specific pages where" />
                    {options.triggerCondition.pages === "specific" ? (
                      <URLDropdown handleInputChange={handleInputChange} options={options} />
                    ) : null}
                  </Radio.Group>
                </Box>
                <Space h={20} />
                <Divider />
                <Space h={20} />
                <Box>
                  <SubHeadingText>Trigger Survey</SubHeadingText>

                  <Radio.Group
                    name="trigger"
                    value={options.triggerCondition.eventType}
                    onChange={val => handleInputChange(val, "triggerCondition", "eventType")}
                    orientation="vertical"
                    spacing="xs"
                  >
                    <Text size={14} color="dimmed">
                      Page events
                    </Text>
                    <Radio label="on page load" value="pageLoad" />
                    <Radio label="after half way scroll" value="halfScroll" />
                    <Radio label="on exiting the page on desktop" value="abandon" />
                    <Text size={14} mt={20} color="dimmed">
                      Click events
                    </Text>
                    <Group spacing="xs">
                      <Radio sx={{ flexGrow: 0 }} label="On clicking element(s) with Inner Text" value="textClick" />
                      {options.triggerCondition.eventType === "textClick" && (
                        <TextInput
                          sx={{ flexGrow: 1, width: "60%" }}
                          styles={() => ({
                            input: {
                              width: "60%",
                            },
                          })}
                          //size="xs"
                          placeholder="Keep it exact (including spaces)"
                          //styles={{ input: { width: 54, textAlign: 'center' } }}
                          value={options.triggerCondition.innerText || ""}
                          onChange={e => handleInputChange(e.currentTarget.value, "triggerCondition", "innerText")}
                        />
                      )}
                    </Group>
                    <Group spacing="xs">
                      <Radio label="On clicking element(s) with CSS selector" value="cssClick" />
                      {options.triggerCondition.eventType === "cssClick" && (
                        <TextInput
                          //size="xs"
                          placeholder="#foo.bar"
                          //styles={{ input: { width: 54, textAlign: 'center' } }}
                          value={options.triggerCondition.cssSelector || ""}
                          onChange={e => handleInputChange(e.currentTarget.value, "triggerCondition", "cssSelector")}
                        />
                      )}
                    </Group>
                  </Radio.Group>
                </Box>
                <Space h={20} />
                <Divider />
                <Space h={20} />
                <Box>
                  <Group>
                    <Text size={16}>Wait for</Text>
                    <NumberInput
                      size="xs"
                      defaultValue={0}
                      min={0}
                      step={1}
                      placeholder="10"
                      styles={{ input: { width: 54, textAlign: "center" } }}
                      value={options.triggerCondition.waitFor}
                      onChange={val => handleInputChange(val, "triggerCondition", "waitFor")}
                    />
                    <Text size="sm">seconds</Text>
                    <Text size="sm">before showing the survey</Text>
                  </Group>
                </Box>

                <Space h={50} />
                <Alert icon={<IconAlertCircle size={16} />} title="Important!" color="yellow" radius="xs">
                  If a user is eligible for multiple surveys on a page, only the most recent one will be displayed.
                </Alert>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="whom-to-show">
              <Accordion.Control>
                <Group position="apart">
                  <div>
                    <Text size="md" weight={400}>
                      Whom to show
                    </Text>
                    <Text size="xs" color="dimmed">
                      Filter users based on their attributes.
                    </Text>
                  </div>
                  <Group>
                    {accordionItem === "whom-to-show" ? null : options.attributeFilters ? (
                      <Group>
                        <TextualTag>{pluralize(options.attributeFilters.length, "Filter", "s")}</TextualTag>
                        <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                      </Group>
                    ) : null}
                  </Group>
                </Group>
                {accordionItem === "whom-to-show" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Space h="md" />

                {options.attributeFilters &&
                  options.attributeFilters.map((filter, Idx) => {
                    return (
                      <AttributeFilter
                        filterCount={options.attributeFilters.length}
                        key={Idx}
                        idx={Idx}
                        token={prodToken}
                        dispatch={dispatch}
                        filter={filter}
                      />
                    );
                  })}

                {/* {options.attributeFilters && options.attributeFilters.length > 1 ? <Text>and</Text> : null} */}
                <Space h="md" />
                <Button type="button" onClick={e => addFilter(e)} className="space10">
                  Add Filter
                </Button>
              </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="customization">
              <Accordion.Control>
                <Group position="apart">
                  <div>
                    <Text size="md" weight={400}>
                      Customization
                    </Text>
                    <Text size="xs" color="dimmed">
                      Select widget type and position.
                    </Text>
                  </div>
                  <Group>
                    {accordionItem === "customization" ? null : (
                      <TextualTag>{humanizedString(options.position.widgetType)}</TextualTag>
                    )}
                    {accordionItem === "customization" ? null : (
                      <Group>
                        {options.position.widgetType === "popover" ? (
                          <TextualTag>{humanizedString(options.position.widgetPosition)}</TextualTag>
                        ) : null}

                        <IconCircleCheck size={30} color="rgb(81, 207, 102)" />
                      </Group>
                    )}
                  </Group>
                </Group>
                {accordionItem === "customization" ? <Divider></Divider> : null}
              </Accordion.Control>
              <Accordion.Panel>
                <Box>
                  <SubHeadingText>Widget Type</SubHeadingText>

                  <Radio.Group
                    value={options.position.widgetType}
                    name="formfactor"
                    onChange={e => handleInputChange(e, "position", "widgetType")}
                    orientation="vertical"
                    spacing="xs"
                  >
                    <Radio label="Popover" value="popover" />
                    <Radio label="Modal" value="modal" />
                  </Radio.Group>
                </Box>
                <Space h="md" />

                {options.position.widgetType === "popover" ? (
                  <WidgetPosition handleInputChange={handleInputChange} options={options} />
                ) : null}
              </Accordion.Panel>
            </Accordion.Item>
          </>
        )}
      </Accordion>

      <Space h="md" />
    </>
  );
}

function URLDropdown(props) {
  const { handleInputChange, options } = props;
  const [placeholder, setPlaceholder] = useState("");

  function handleURLChange(value, section, field) {
    console.log(value);
    const selectedOption = value;

    setPlaceholder(urlMatchOptions.find(x => x.value === value)?.placeholder);

    handleInputChange(selectedOption, section, field);
  }

  const urlMatchOptions = [
    {
      key: "exactMatch",
      value: "exactMatch",
      label: "URL exactly matches",
      placeholder: "e.g. http://www.website.com?product=book",
    },
    {
      key: "startsWith",
      value: "startsWith",
      label: "URL starts with",
      placeholder: "e.g. http://www.website.com/reviews",
    },
    {
      key: "endsWith",
      value: "endsWith",
      label: "URL ends with",
      placeholder: "e.g. /reviews",
    },
    { key: "contains", value: "contains", label: "URL contains", placeholder: "e.g. checkout" },
    { key: "regex", value: "regex", label: "URL regex", placeholder: "e.g. {'\\/product\\/[^\\/]+'}" },
  ];

  const ValueAndPlaceholder = () => {
    let matchValue = urlMatchOptions.find(x => x.value === options.triggerCondition.matchCriteria);
    const obj = {
      value: matchValue ? matchValue.value : "",
      placeholder: matchValue ? matchValue.label : "Select Criteria",
    };
    return obj;
  };

  return (
    <>
      <Group>
        <StyledSelect
          value={ValueAndPlaceholder().value}
          onChange={e => handleURLChange(e, "triggerCondition", "matchCriteria")}
          data={urlMatchOptions}
          placeholder={ValueAndPlaceholder().placeholder}
          width="200px"
        />

        <TextInput
          id="urlToMatch"
          placeholder={placeholder}
          value={options.triggerCondition.urlToMatch}
          onChange={e => handleInputChange(e.target.value, "triggerCondition", "urlToMatch")}
          sx={{ width: "360px" }}
        />
      </Group>
    </>
  );
}

function WidgetPosition(props) {
  const { handleInputChange, options } = props;
  return (
    <Box>
      <SubHeadingText>Widget Position</SubHeadingText>
      <Radio.Group
        value={options.position.widgetPosition}
        name="widgetPosition"
        onChange={e => handleInputChange(e, "position", "widgetPosition")}
        orientation="vertical"
        spacing="xs"
      >
        <Radio label="Botton Right" value="bottom_right" />
        <Radio label="Bottom Left" value="bottom_left" />
      </Radio.Group>
    </Box>
  );
}
