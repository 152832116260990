import React, { useEffect } from "react";
import { useAuthState } from "../../context/AuthContext";
import { useAPICall } from "../../hooks";
import { getSurveyWithSummary } from "../../api/Survey";
import API from "../../api/API";
import { Button, Title, Box, Grid, Mark, Flex, Container, Text, Divider, Card, Accordion, Space } from "@mantine/core";
import { Link } from "react-router-dom";
import { IconPlus } from "@tabler/icons-react";
import { API_URLS } from "../../utils/constants";
import { FullPageSpinner } from "../../components/spinners";
import SurveyListItem from "./components/SurveyListItem";
import { Heading } from "../../components";
import surveyImage from "./../../images/SurveyCreation.png";
import { InstallCodeBanner } from "../../components/InstallCodeBanner";
import { showErrorNotification } from "../../components/notifications";
import { FormattedMessage, useIntl } from "react-intl";

export default function SurveyList() {
  const auth = useAuthState();
  const intl = useIntl();
  const csrfToken = auth.csrftoken;
  const currentUser = auth.user;
  const currentProduct = auth.currentProduct;
  const userHasPermission = currentUser.isAccountOwner || currentUser.accessLevel == 1 || currentUser.accessLevel == 2;

  console.log("auth in SurveyList is", auth);
  const [surveyList, getsurveyList] = useAPICall(() =>
    getSurveyWithSummary(auth.currentProduct ? auth.currentProduct.prodToken : auth.organization.products[0].prodToken)
  );

  useEffect(() => {
    getsurveyList();
  }, []);

  const currentUserSurveys = surveyList.data && surveyList.data.filter(survey => survey.user_id === auth.user.id);
  // console.log("1243 authUserid", currentUserSurveys);
  // console.log("1243 surveyUserid", auth.user.id);
  console.log("1243 currentUserSurveys", currentUserSurveys);
  const restOfTheSurveys = surveyList.data && surveyList.data.filter(survey => survey.user_id !== auth.user.id);
  console.log("1243 restOfTheSurveys", restOfTheSurveys);

  async function handleDelete(e, id) {
    e.preventDefault();
    console.log("CSRF Token in SurveyList is", csrfToken);
    let response = await API.delete(API_URLS.UPDATE_SURVEY(id), csrfToken);
    if (response.success) {
      getsurveyList();
    } else {
      showErrorNotification(response.error.message);
    }
  }

  if (surveyList.loading) {
    return <FullPageSpinner />;
  } else if (surveyList.error) {
    return (
      <div>
        <FormattedMessage id="common.loading_error" />
      </div>
    );
  }

  if (surveyList.data && surveyList.data.length === 0) {
    return (
      <Container mt={60}>
        <Flex mih={50} gap="0" justify="center" align="center" direction="column" wrap="nowrap">
          <Title sx={{ margin: 0 }}>
            <FormattedMessage
              id="survey_list_page.no_content_heading"
              values={{
                mark: chunks => <Mark sx={{ borderRadius: "2px", paddingBottom: "2px" }}>&nbsp;{chunks}&nbsp;</Mark>,
              }}
            />
          </Title>
          <Text>{intl.formatMessage({ id: "survey_list_page.no_content_subheading" })}</Text>
          <Link to="/surveys/new">
            <Button mt={30} leftIcon={<IconPlus />}>
              <FormattedMessage id="survey_list_page.no_content_cta" />
            </Button>
          </Link>
          <Box mt={30}>
            <img
              alt={intl.formatMessage({ id: "survey_list_page.no_content_hero_alt" })}
              style={{ height: "300px", border: "6px solid #CDE6F5", borderRadius: "4px" }}
              src={surveyImage}
            />
          </Box>
        </Flex>
      </Container>
    );
  } else {
    return (
      <div>
        {currentProduct.lastProdInitAt ? null : <InstallCodeBanner token={currentProduct.prodToken} />}
        <Heading title={intl.formatMessage({ id: "survey_list_page.heading" })} />

        {userHasPermission ? (
          <Accordion variant="contained" defaultValue="currentUserSurveys">
            <Accordion.Item value="currentUserSurveys">
              <Accordion.Control>
                <Text fw={700}>Your research ({currentUserSurveys.length})</Text>
              </Accordion.Control>
              <Accordion.Panel>
                {currentUserSurveys.length > 0 ? (
                  <>
                    <Grid>
                      {currentUserSurveys &&
                        currentUserSurveys.sort().map(survey => (
                          <Grid.Col key={survey.id} md={6} lg={3} sx={{ position: "relative" }}>
                            <SurveyListItem survey={survey} handleDelete={handleDelete} currentUser={currentUser} />
                          </Grid.Col>
                        ))}
                    </Grid>
                  </>
                ) : (
                  <Box>
                    <Text italic size={14} color="dimmed">
                      What do you want to know? Start by creating a new survey.
                    </Text>
                  </Box>
                )}
              </Accordion.Panel>
            </Accordion.Item>
            <Space my="md" />

            <Accordion.Item value="restOfTheSurveys">
              <Accordion.Control>
                <Text fw={700}>Rest ({restOfTheSurveys.length})</Text>
              </Accordion.Control>
              <Accordion.Panel>
                {restOfTheSurveys.length > 0 && (
                  <Grid>
                    {restOfTheSurveys &&
                      restOfTheSurveys.sort().map(survey => (
                        <Grid.Col key={survey.id} md={6} lg={3} sx={{ position: "relative" }}>
                          <SurveyListItem survey={survey} handleDelete={handleDelete} currentUser={currentUser} />
                        </Grid.Col>
                      ))}
                  </Grid>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        ) : (
          <Grid>
            {surveyList.data &&
              surveyList.data.sort().map(survey => (
                <Grid.Col key={survey.id} md={6} lg={3} sx={{ position: "relative" }}>
                  <SurveyListItem survey={survey} handleDelete={handleDelete} currentUser={currentUser} />
                </Grid.Col>
              ))}
          </Grid>
        )}
      </div>
    );
  }
}
