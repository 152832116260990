export const en = {
  //-----------------Common Words--------------------
  "common.save": "Save",
  "common.save_and_continue": "Save & Continue",
  "common.cancel": "Cancel",
  "common.apply": "Apply",
  "common.delete": "Delete",
  "common.duplicate": "Duplicate",
  "common.edit": "Edit",
  "common.add": "Add",
  "common.remove": "Remove",
  "common.yes": "Yes",
  "common.no": "No",
  "common.next": "Next",
  "common.back": "Back",
  "common.done": "Done",
  "common.close": "Close",
  "common.upload": "Upload",
  "common.upload_logo": "Upload Logo",
  "common.email": "Email",
  "common.name": "Name",
  "common.full_name": "Full Name",
  "common.status": "Status",
  "common.loading_error": "Something went wrong. Please try again.",
  "common.loading": "Loading...",
  "common.success": "Success",
  "common.error": "Error",
  "common.copied": "Copied",
  "common.copy": "Copy",
  "common.questions": "Questions",
  "common.string": "String",
  "common.boolean": "Boolean",
  "common.number": "Number",
  "common.date": "Date",
  "common.load_more": "Load more",
  "common.view_all": "View all",
  "common.view_less": "View less",
  "common.user": "User",
  "common.responses": "Responses",
  "common.response": "Response",
  "common.go_back": "Go back",
  "common.survey": "Survey",
  "common.other": "Other",
  "common.pick_one": "Pick one",

  //-----------------Nav--------------------
  "nav.new_survey": "New Survey",
  "nav.survey_list": "Survey List",
  "nav.user_attributes": "User Attributes",
  "nav.connect": "Connect",
  "nav.account": "Account",
  "nav.organization": "Organization",
  "nav.team": "Team",
  "nav.settings": "Settings",
  "nav.logout": "Logout",
  "nav.help": "Help",
  "nav.integrations": "Integrations",
  "nav.sites": "Sites",
  //-----------------Dashboard Page-----------------------
  "dashboard_page.heading": "Dashboard",
  "dashboard_page.subheading": "Welcome to Spark!",
  "dashboard_page.survey": "Survey",
  "dashboard_page.surveys": "Surveys",
  "dashboard_page.respondents": "Respondents",
  "dashboard_page.responses": "Responses",
  "dashboard_page.view_all": "View all",
  //-----------------Survey Page--------------------------
  "survey_page.heading": "Survey",
  "survey_page.subheading": "Create your survey by adding questions.",

  //-----------------New Survey Page--------------------
  "new_survey_page.heading": "New Survey",
  "new_survey_page.subheading": "Use a template from below or create from scratch.",
  "new_survey_page.create_button": "Create Survey from scratch",
  //-----------------Templates
  "templates.nps": "NPS",
  "templates.nps_desc": "How happy are your customers with the offering?",
  "templates.feature_launch": "Feature Launch",
  "templates.feature_launch_desc": "Ideal for assessing impact of newly launched features.",
  "templates.pmf": "Product Market Fit",
  "templates.pmf_desc": "Have you reached the fit?",
  "templates.feature_discovery": "Feature Discovery",
  "templates.feature_discovery_desc": "Understand what features are most important to your users.",
  "templates.csat": "CSAT",
  "templates.csat_desc": "Measure how satisfied are your customers with your product or service?",
  //-----------------Survey Listing Page--------------------
  "survey_list_page.heading": "Surveys",
  "survey_list_page.no_content_heading": "Understand <mark>why</mark> of your users' decisions",
  "survey_list_page.no_content_subheading": "Launch a in-product survey and start getting insights.",
  "survey_list_page.no_content_cta": "New Survey",
  "survey_list_page.no_content_hero_alt": "Survey question creation form",
  "survey_list_page.started_on": "Started on {date}",
  "survey_list_page.ended_on": "Ended on {date}",

  //-----------------User Attributes Page--------------------
  "user_attributes_page.heading": "User Attributes",
  "user_attributes_page.attribute": "Attribute",
  "user_attributes_page.type": "Type",
  "user_attributes_page.last_received": "Last Received",
  "user_attributes_page.month": "month",
  "user_attributes_page.ago": "ago",
  "user_attributes_page.months": "months",
  "user_attributes_page.string": "String",
  "user_attributes_page.boolean": "Boolean",
  "user_attributes_page.number": "Number",
  "user_attributes_page.date": "Date",
  "user_attributes_page.no_data_heading": "Reach out to relevant users",
  "user_attributes_page.no_data_subheading":
    "User attributes are additional data points that you already have about your users. You can send this information to FeedbackSpark to show your survey to a specific segment of users, for e.g. users who purchased in last 60 days.",
  //-----------------Connect Page--------------------
  "connect_page.heading": "Connect",
  "connect_page.html_help_text":
    "Copy the code below and paste it between the <mark>{html_tag}</mark> tags on all the pages where you want to show the survey.",
  "connect_page.npm": "NPM",
  "connect_page.Yarn": "Yarn",
  "connect_page.npm_initialize_spark": "Next in your application, as early as possible, initialize Spark.",
  "connect_page.recommeneded": "Recommended",
  //-----------------Organization Page--------------------
  "org_page.heading": "Organization",

  "org_page.website": "Website",
  "org_page.api_key": "API Key",
  "org_page.view_code": "View installation code",
  "org_page.logo": "Logo",
  "org_page.logo_desc": "This logo will be displayed in the survey page.",
  "org_page.upload_logo_btn": "Upload Logo",
  //-----------------Teams Page--------------------
  "team_page.heading": "Team",
  "team_page.access": "Access",
  "team_page.date_joined": "Date Joined",
  "team_page.members": "Members",
  "team_page.add_member_btn": "Add Member",
  "team_page.add_team_member": "Add Team Member",
  "team_page.access_level": "Access Level",
  "team_page.access_level_placeholder": "Select role",
  "team_page.access_viewer": "Viewer",
  "team_page.access_editor": "Editor",
  "team_page.you": "You",
  "team_page.role": "Role",
  "team_page.invited": "Invited",
  "team_page.accepted": "Accepted",
  "team_page.rejected": "Rejected",
  "team_page.resend_invite": "Resend Invite",
  "team_page.cancel_invite": "Cancel Invite",
  "team_page.remove_member": "Remove Member",
  //-----------------Settings Page--------------------

  "settings_page.heading": "Settings",
  "settings_page.product": "Product",
  "settings_page.product_desc": "Name is used to automatically fill in survey templates.",
  "settings_page.website": "Website",
  "settings_page.timezone": "Timezone",
  "settings_page.appearance": "Appearance",
  "settings_page.appearance_desc": "Customize the look and feel of the survey.",
  "settings_page.background": "Background",
  "settings_page.background_desc": "Background color for the survey.",
  "settings_page.font_color": "Font Color",
  "settings_page.font_color_desc": "Question & options font color.",
  "settings_page.button": "Button",
  "settings_page.button_desc": "Call to action button background color.",
  "settings_page.button_font_color": "Button Font Color",
  "settings_page.button_font_color_desc": "Call to action button font color.",
  "settings_page.border": "Border",
  "settings_page.border_desc": "Widget and input field border color",
  "settings_page.reconnect_period": "Reconnect Period",
  "settings_page.reconnect_period_desc":
    "Minimum number of days before same user is shown their next survey. Defaults to 7 days.",
  "settings_page.sample_question": "Sample Question Text",
  "settings_page.option1": "Option 1",
  "settings_page.option2": "Option 2",
  "settings_page.i_think": "I think...",
  "settings_page.done": "Done",
  "settings_page.powered_by": "Powered by",
  //-----------------Error Notifications--------------------
  "notif.sorry": "Sorry",
  "message.error": "Something went wrong",
  "message.no_delivery_platform": "Please add a delivery platform.",
  "message.no_question": "[Survey] Please add a question to the survey.",
  "message.incomplete_questions": "[Survey] Please complete all the questions.",
  "message.survey_saved": "Survey saved successfully.",
  "message.survey_not_saved": "An error occurred while saving the survey.",
  "message.identifyUserNotSet": "[Audience] Please select if you want to identify multiple responses.",
  "message.userIdentifierNotSet": "[Audience] Please select a user identifier key.",

  //-----------------Reports--------------------
  "reports.report_tab": "Report",
  "reports.responses_tab": "Responses",
  "reports.filter": "Filter",
  "reports.custom": "Custom",
  "reports.today": "Today",
  "reports.yesterday": "Yesterday",
  "reports.download_csv": "Download CSV",
  "reports.survey_link": "Survey Link",
  "reports.copied_html": "Copied HTML",
  "reports.copy_html": "Copy HTML",
  "reports.unique_views": "Unique Views",
  "reports.unique_views_desc": "Number of unique users who saw the survey at least once.",
  "reports.answered": "Answered",
  "reports.answered_desc": "Number of unique users who answered at least one question.",
  "reports.completed": "Completed",
  "reports.completed_desc": "Number of unique users who answered all the questions.",
  "reports.response_rate": "Response Rate",
  "reports.response_rate_desc": "Percentage of unique users who answered at least one question.",
  "reports.completion_rate": "Completion Rate",
  "reports.completion_rate_desc": "Percentage of unique users who completed all the questions.",
  "reports.most_responses_from": "Most responses from",
  "reports.view_more": "View more",
  "reports.not_set": "Not set",
  "reports.hide_filter": "Hide Filter",
  "reports.custom_date": "Custom",
  "reports.filter_start_date": "Start Date",
  "reports.filter_end_date": "End Date",
  "reports.all_filters": "All Filters",
  "reports.filter_by": "Filter by",
  "reports.country_stat_heading": "Most Responses From",
  "reports.country_stat_subheading": "Respondents by Country (Top 5)",
  "reports.country_stat_help": "View More",
  "reports.day": "Day",
  "reports.week": "Week",
  "reports.month": "Month",
  "reports.response_count": "Response count",
  "reports.rating": "Rating",
  "reports.response_count_plural": "{count, plural, one {# Response} other {# Responses}}",
  "reports.nps_promoters": "Promoters",
  "reports.nps_passives": "Passives",
  "reports.nps_detractors": "Detractors",
  "reports.total": "total",
  "reports.rating_positive": "Positive",
  "reports.rating_negative": "Negative",
  "reports.rating_neutral": "Neutral",
  "reports.trend_heading": "Trend",
  "reports.percent_share": "Share",
  "reports.rating_score_tooltip": "Percentage of users who gave 4 and 5 ratings.",
  "reports.no_data_msg": "No data available for this period.",
  "reports.identified_themes": "Identified Themes",
  "reports.itdentified_tags": "Identified Tags",
  "reports.count": "Count",
  "reports.percent": "Percent",
  "reports.no_ai_analysis":
    "You need at least ten responses to build a pattern. Meanwhile, you can <link>check the verbatim responses</link>.",
  "reports.sentiment": "Sentiment",
  "reports.nhs": "Net Happiness Score",
  "reports.nhs_desc": "Total % of Positive  – Total % Negative = Net Happiness Score.",
  "reports.ai_allthemes": "All Themes",
  "reports.ai_topthemes": "Top Themes",
  "reports.ai_themecutoff_desc": "Themes with at least 3% share are shown.",

  //-----------------Filters--------------------
  "filters.filter": "Filter",

  "filters.response": "Response",
  "filters.country": "Country",
  "filters.clear": "Clear",
  "filters.response_to": "Response to",
  "filters.select_question": "Select Question",
  "filters.apply": "Apply Filters",
  "filters.equals": "Equals",
  "filters.select_country": "Select country",
  "filters.country_equals": "Country Equals",

  //-----------------Surveys General--------------------
  "survey.live": "Live",
  "survey.in_draft": "In Draft",
  "survey.pause": "Pause",
  "survey.paused": "Paused",
  "survey.completed": "Completed",
  "survey.archive": "Archive",
  "survey.archived": "Archived",
  "survey.mark_complete": "Mark Complete",
  "survey.launch": "Launch",
  "survey.draft": "Draft",
  "survey.platform": "Platform",
  "survey.audience": "Audience",
  "survey.notifications": "Notifications",
  "survey.review": "Review",
  "survey.continue": "Continue",
  "survey.name_placeholder": "Survey name",
  "survey.name_label": "Survey name",
  "survey.add_questions": "Add questions",
  "surveys.question_count_plural": "{count, plural, one {# Question} other {# Questions}}",
  "survey.limited_editing_title": "Limited Editing",
  "survey.limited_editing_desc":
    "This survey is live. You can only change the language. If you want to make other changes , we suggest that you mark this survey complete and start a new one.",
  "survey.platform_website": "Website",
  "survey.platform_website_desc": "Show survey inside your website.",
  "survey.platform_email": "Email",
  "survey.platform_email_desc": "Embed survey in an email.",
  "survey.platform_link": "Link",
  "survey.platform_link_desc": "Share unique survey link with your users.",
  "survey.select_delivery_platform": "Select survey delivery platform.",
  "survey.delivery_platform_label": "Email Delivery Platform",
  "survey.delivery_platform_placeholder": "Pick one",
  "survey.delivery_platform_tip": "{platform_tag} tag will be used to parse respondent email.",
  "survey.select_language": "Select language",
  "survey.lang_english": "English",
  "survey.lang_spanish": "Spanish",
  "survey.lang_french": "French",
  "survey.lang_german": "German",
  "survey.lang_italian": "Italian",
  "survey.lang_portuguese": "Portuguese",
  "survey.lang_dutch": "Dutch",
  "survey.lang_norwegian": "Norwegian",
  "survey.lang_greek": "Greek",
  "survey.lang_hindi": "Hindi",
  "survey.lang_swedish": "Swedish",
  "survey.lang_japanese": "Japanese",
  "survey.lang_finnish": "Finnish",
  "survey.lang_indonesian": "Indonesian",
  "survey.lang_czech": "Czech",
  "survey.lang_danish": "Danish",
  "survey.lang_thai": "Thai",
  "survey.lang_tamil": "Tamil",
  "survey.introduction_label": "Introduction",
  "survey.introduction_button_label": "Button text (leads to first question)",
  "survey.open_text": "Open Text",
  "survey.mcq": "MCQ",
  "survey.nps": "NPS",
  "survey.rating": "Rating",
  "survey.q_email": "Email",
  "survey.q_redirect": "Redirect",
  "survey.mcq_single": "MCQ - Single Select",
  "survey.mcq_multi": "MCQ - Multi Select",
  "survey.logic_next_thankyou": "Any other response continue to Thank you.",
  "survey.logic_next_question": "Any other response continue to next question.",
  "survey.btn_add_logic": "Add Logic",
  "survey.logic_alert": "Some of the questions have skip logic conditions. Moving may cause logical error.",
  "survey.q_required": "Required",
  "survey.add_element": "Add Element",
  "survey.add_element_here": "Add Element here",

  // -------------- Integrations ----------------
  "integrations.add_webhook_btn": "Add Webhook",
  "integrations.connect": "Connect",
  "integrations.disconnect": "Disconnect",
  "integrations.webhook_heading": "Webhooks",
  "integrations.webhook_subheading": "Send survey data to external URLs.",
  "integrations.del_webhook_integration_alert_modal_heading": "Are you sure?",
  "integrations.del_webhook_integration_alert_modal_message":
    "This will delete all existing webhooks and the configuration.",
  "integrations.webhook_trigger_label": "Trigger",
  "integrations.webhook_trigger_response": "On each response",
  "integrations.webhook_trigger_completed": "On survey completion",
  "integrations.webhook_url_label": "URL (Only HTTPS)",
  "integrations.webhook_name_placeholder": "Webhook for [destination]",
  "integrations.configure_webhook_btn": "Configure Webhook",
  "integrations.zapier_subheading": "Send survey data to 7000+ apps.",
};
