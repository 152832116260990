import React from "react";
import { Menu, Button, Text, Group, Box } from "@mantine/core";
import {
  IconMoodHappy,
  Icon123,
  IconClipboardCheck,
  IconEdit,
  IconClipboardList,
  IconExternalLink,
  IconChevronDown,
  IconAt,
} from "@tabler/icons-react";
import style from "../../../../style.scss";

export function QuestionChangeMenu(props) {
  const { dispatch, idx, platform, questions, productName, currentQuestion, intl } = props;

  const menuOptions = [
    {
      value: 1,
      code: "open_text",
      label: intl.formatMessage({ id: "survey.open_text", defaultMessage: "Open Text" }),
      icon: <IconEdit size={14} />,
    },
    {
      value: 4,
      code: "nps",
      label: intl.formatMessage({ id: "survey.nps", defaultMessage: "NPS" }),
      icon: <IconMoodHappy size={14} />,
    },
    {
      value: 2,
      code: "mcq_single",
      label: intl.formatMessage({ id: "survey.mcq_single", defaultMessage: "MCQ - Single Select" }),
      icon: <IconClipboardCheck size={14} />,
    },
    {
      value: 3,
      code: "mcq_multi",
      label: intl.formatMessage({ id: "survey.mcq_multi", defaultMessage: "MCQ - Multi Select" }),
      icon: <IconClipboardList size={14} />,
    },
    {
      value: 5,
      code: "rating",
      label: intl.formatMessage({ id: "survey.rating", defaultMessage: "Rating" }),
      icon: <Icon123 size={14} />,
    },
    {
      value: 12,
      code: "email",
      label: intl.formatMessage({ id: "survey.q_email", defaultMessage: "Email" }),
      icon: <IconAt size={14} />,
    },
    {
      value: 30,
      code: "redirect",
      label: intl.formatMessage({ id: "survey.q_redirect", defaultMessage: "Redirect" }),
      icon: <IconExternalLink size={14} />,
    },
    // { value: 6, code: 'binary', label: 'Binary', icon: <IconArrowsLeftRight size={14} /> },
  ];

  function handleQuestionChange(event, newQuestionType) {
    event.stopPropagation();
    console.log("qType in questionTypeDropown is", newQuestionType);

    dispatch({
      type: "changeQuestion",
      index: idx,
      newQuestionType: newQuestionType,
      productName: productName,
    });
  }

  const currentQuestionOption = menuOptions.find(x => x.value === currentQuestion.questionType);

  return (
    <Menu
      shadow="md"
      width={300}
      position="bottom-start"
      transitionDuration={0}
      styles={() => ({
        itemLabel: {
          fontSize: "1.6rem",
        },
      })}
    >
      <Menu.Target>
        <Box
          onClick={event => event.stopPropagation()}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "4px",
            border: "1px solid transparent",
            fontSize: "14px",
            fontWeight: 400,
            color: "#868e96",
            width: "auto",
            backgroundColor: "rgba(248, 249, 250, 1)",
            cursor: "pointer",
            padding: "0px 8px",
          }}
        >
          <Group spacing={4}>
            {currentQuestionOption && currentQuestionOption.icon}
            <Text>{currentQuestionOption && currentQuestionOption.label}</Text>
            <IconChevronDown size={14} />
          </Group>
        </Box>
        {/* <Button
          variant="light"
          color="gray"
          compact
          rightIcon={<IconChevronDown size={14} />}
          onClick={event => event.stopPropagation()}
          styles={{
            label: {
              fontWeight: 400,
              fontSize: "14px",
            },
          }}
        >
          <Group spacing={4}>
            {currentQuestionOption && currentQuestionOption.icon}
            <Text>{currentQuestionOption && currentQuestionOption.label}</Text>
          </Group>
        </Button> */}
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Questions</Menu.Label>
        <Menu.Item icon={<IconClipboardCheck size={14} />} onClick={e => handleQuestionChange(e, "mcq_single")}>
          Multiple Choice: Single Select
        </Menu.Item>
        <Menu.Item
          icon={<IconClipboardList size={14} />}
          disabled={platform === "email" && currentQuestion.order == 0}
          className={style.menuLabel}
          onClick={e => handleQuestionChange(e, "mcq_multi")}
        >
          Multiple Choice: Multi Select
        </Menu.Item>
        <Menu.Item
          icon={<IconEdit size={14} />}
          disabled={platform === "email" && currentQuestion.order == 0}
          onClick={e => handleQuestionChange(e, "open_text")}
        >
          <Text>Open Text</Text>
        </Menu.Item>
        <Menu.Item icon={<IconMoodHappy size={14} />} onClick={e => handleQuestionChange(e, "nps")}>
          NPS
        </Menu.Item>
        <Menu.Item
          icon={<Icon123 size={14} />}
          disabled={platform === "email" && currentQuestion.order == 0}
          onClick={e => handleQuestionChange(e, "rating")}
        >
          Rating
        </Menu.Item>
        <Menu.Label>Other</Menu.Label>
        <Menu.Item
          icon={<IconAt size={14} />}
          onClick={e => handleQuestionChange(e, "email")}
          disabled={platform === "email" && currentQuestion.order == 0}
        >
          Email
        </Menu.Item>
        <Menu.Item
          icon={<IconExternalLink size={14} />}
          onClick={e => handleQuestionChange(e, "redirect")}
          disabled={platform === "email" && currentQuestion.order == 0}
        >
          Redirect
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
