import React, { useState, useEffect } from "react";
import {
  Select,
  Radio,
  Loader,
  Card,
  Group,
  Text,
  Menu,
  Flex,
  Stack,
  Checkbox,
  Input,
  Anchor,
  NumberInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { Calendar, RangeCalendar } from "@mantine/dates";
import { getLastNDiffDates, pluralize } from "../../../../utils/helpers";
import dayjs from "dayjs";
import style from "../../../../style.scss";
import AttributeStringValueSelector from "./AttributeStringValueSelector";

function AttributeValueSelector({ idx, filterObject, attributeValues, handleChange, dispatch }) {
  const { operator, datatype } = filterObject;

  const [rangeValue, setRangeValue] = useState(getLastNDiffDates());
  const [dateValue, setDateValue] = useState(new Date());
  const [unitCount, setUnitCount] = useState(7);
  const [unit, setUnit] = useState("day");
  const [operatorOpened, setOperatorOpened] = useState(false);
  const [numValue, setNumValue] = useState(parseInt(filterObject.predicate));

  console.log("#4e1.1 attributeValues in AttributeValueSelector", attributeValues);
  console.log("#4e1.2 filterObject in AttributeValueSelector", filterObject);

  // useEffect(() => {
  //   console.log('3344 filterstate', filterObject);
  //   if (filterObject) {
  //     const allFilled = filterObject.value !== undefined && filterObject.value !== '';
  //     if (allFilled) {
  //       handleFilterSubmit();
  //     }
  //   }
  // }, [filterObject.value]);

  // function handleFilterSubmit() {
  //   console.log('3333 Submitting Data', filterObject);
  // }

  console.log("#c1 filterObject", filterObject);

  function handleUnitCountChange(e) {
    console.log("handleDaysCountChange", e.target.value);
    setUnitCount(e.target.value);
    setRangeValue(getLastNDiffDates(e.target.value, unit));
  }
  function handleUnitChange(val) {
    setUnit(val);
    setRangeValue(getLastNDiffDates(unitCount, val));
  }

  function handleNumSubmit(e) {
    e.preventDefault();

    dispatch({
      type: "updateFilter",
      filterType: "attributeFilter",
      index: idx,
      section: "predicate",
      value: numValue,
    });
    console.log("999");
  }

  function handleApplyFilter(val) {
    console.log("filter Object", filterObject);
    dispatch({
      type: "updateFilter",
      filterType: "attributeFilter",
      index: idx,
      section: "predicate",
      value: val,
    });
    console.log("999", filterObject);
  }

  function handleCalendarChange(val) {
    console.log(val);
    setRangeValue(val);
    if (val[1] === null) {
      setUnitCount(dayjs(val[0]).diff(dayjs(val[0]), "day"));
    } else {
      setUnitCount(dayjs(val[1]).diff(dayjs(val[0]), "day"));
    }
    dispatch({
      type: "updateFilter",
      filterType: "attributeFilter",
      index: idx,
      section: "predicate",
      value: [dayjs(val[0]).valueOf(), dayjs(val[1]).valueOf()],
    });
  }

  if (datatype === "boolean" && operator) {
    return (
      <Radio.Group
        value={filterObject.predicate}
        onChange={val => handleChange(idx, "predicate", val)}
        name="Boolean Attribute"
      >
        <Radio value="true" label="True" />
        <Radio value="false" label="False" />
      </Radio.Group>
    );
  } else if (datatype === "number" && operator) {
    return (
      <form onSubmit={e => handleNumSubmit(e)}>
        <NumberInput hideControls value={numValue} onChange={val => setNumValue(val)} />
      </form>
    );
  } else if (datatype === "date" && operator) {
    switch (operator) {
      case "last":
      case "notinlast":
        return (
          <>
            <Menu opened={operatorOpened} onChange={setOperatorOpened} position="bottom-start" shadow="sm">
              <Menu.Target>
                <Text className={style.filterHighlighter} size={14}>
                  {pluralize(unitCount, unit, "s")}
                </Text>
              </Menu.Target>

              <Menu.Dropdown className={style.filterDropdown}>
                <Group>
                  <Input sx={{ width: "120px" }} value={unitCount} onChange={val => handleUnitCountChange(val)} />
                  <Select
                    sx={{ width: "120px" }}
                    value={unit}
                    onChange={val => handleUnitChange(val)}
                    data={[
                      { value: "day", label: unitCount > 1 ? "Days" : "Day" },
                      { value: "week", label: unitCount > 1 ? "Weeks" : "Week" },
                      { value: "month", label: unitCount > 1 ? "Months" : "month" },
                    ]}
                  />
                </Group>

                <RangeCalendar
                  //value={rangeValue}
                  range={rangeValue}
                  allowLevelChange={false}
                  focusable={true}
                  allowSingleDateInRange={false}
                  onChange={handleCalendarChange}
                  maxDate={new Date()}
                  //minDate={new Date(2023, 8, 1)}
                />
                <Group position="apart" mt={20}>
                  <Anchor color="dimmed" onClick={() => setOpened(false)}>
                    Cancel
                  </Anchor>
                  <Anchor onClick={() => handleApplyFilter(`${unitCount}-${unit}`)}>Add</Anchor>
                </Group>

                {/* <DateRangePicker placeholder="Pick dates range" value={dateValue} onChange={setDateValue} /> */}
              </Menu.Dropdown>
            </Menu>
            <Card>{console.log("RangeValue", rangeValue)}</Card>
          </>
        );
      case "between":
        return (
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <Text className={style.filterHighlighter} size={14}>
                {rangeValue[0] ? dayjs(rangeValue[0]).format("MMM D, YY") : "Start Date"} -{" "}
                {rangeValue[1] ? dayjs(rangeValue[1]).format("MMM D, YY") : "End Date"}
              </Text>
            </Menu.Target>

            <Menu.Dropdown>
              <RangeCalendar
                value={rangeValue}
                allowLevelChange={false}
                allowSingleDateInRange={true}
                onChange={setRangeValue}
                maxDate={new Date()}
              />
              <Group>
                <Text>Cancel</Text>
                <Text>Apply</Text>
              </Group>

              {/* <DateRangePicker placeholder="Pick dates range" value={dateValue} onChange={setDateValue} /> */}
            </Menu.Dropdown>
          </Menu>
        );
      case "on":
      case "noton":
      case "before":
      case "since":
        return (
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <Text className={style.filterHighlighter} size={14}>
                {dateValue ? dayjs(dateValue).format("MMM D, YY") : "Pick Date"}
              </Text>
            </Menu.Target>

            <Menu.Dropdown>
              <Calendar value={dateValue} onChange={setDateValue} />
              <Group>
                <Text>Cancel</Text>
                <Text>Apply</Text>
              </Group>

              {/* <DateRangePicker placeholder="Pick dates range" value={dateValue} onChange={setDateValue} /> */}
            </Menu.Dropdown>
          </Menu>
        );

      default:
        break;
    }
  } else {
    return attributeValues ? (
      <AttributeStringValueSelector
        filterObject={filterObject}
        attributeValues={attributeValues}
        dispatch={dispatch}
        idx={idx}
      />
    ) : (
      <Loader />
    );
  }
}

export default AttributeValueSelector;
