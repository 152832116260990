import React, { useState, useEffect, memo, Suspense } from "react";
import { SurveyUserResponse } from "./components/SurveyUserResponse";
import { Space, Loader, Text, Box, Center, Group, Button } from "@mantine/core";
import API from "../../api/API";
const RespondentDetailDrawer = React.lazy(() => import("../../components/RespondentDetailDrawer"));
import { useAuthState } from "../../context/AuthContext";
import { fetchPaginatedResponses } from "../../api/Survey";
import NoData from "./components/reports/NoData";
import { showErrorNotification } from "../../components/notifications";
import { API_URLS } from "../../utils/constants";

export default memo(function SurveyResponseVerbatim(props) {
  const { surveyId, questions, platform, dateRange, setSearchParams, searchParams, owner } = props;

  const [responseData, setResponseData] = useState([]);
  const [userResponses, setUserResponses] = useState();
  const [opened, setOpened] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [nextURL, setNextURL] = useState();
  const { csrftoken } = useAuthState();

  const respondentId = searchParams.get("respondent") && searchParams.get("respondent").split("/")[0];

  const fetchURL =
    `surveys/${surveyId}/responses/?start_date=${dateRange[0]}&end_date=${dateRange[1]}` +
    (searchParams.get("filterby_response") ||
    searchParams.get("filterby_country") ||
    searchParams.get("filterby_attribute")
      ? `&${searchParams.toString()}`
      : "");

  // const endRef = useRef(null);
  // const scrollToBottom = () => {
  //   console.log('scrollToBottomCalled', endRef.current);
  //   endRef.current?.scrollIntoView({ behavior: 'smooth', bloc: 'end' });
  // };

  useEffect(() => {
    fetchPaginatedResponses(fetchURL)
      .then(data => {
        setResponseData(data.results);

        if (data.next) {
          setNextURL(data.next);
        }
      })
      .catch(error => {
        console.log("error in fetchPaginatedResponses", error);
        setError(error);
      })
      .finally(setLoader(false));
  }, [dateRange, searchParams.get("filterby_response"), searchParams.get("filterby_country"), searchParams.get("filterby_attribute")]);

  useEffect(() => {
    if (respondentId) {
      API.get(`surveys/${surveyId}/respondents/${respondentId}/responses/`).then(response => {
        if (response.success) {
          console.log("@respondent", response.success.data);
          setUserResponses(response.success.data);
        } else {
          //showErrorNotification(response.error);
          console.error(response.error);
        }
      });
    }
  }, [searchParams.get("respondent")]);

  // useEffect(() => {
  //   scrollToBottom();
  // }, [responseData]);

  // const isLoading = surveyResponses.loading;
  // const dataAvailable = surveyResponses.data !== null;
  // const responseData = surveyResponses.data;

  const dataCards = data => {
    console.log("@dataCards", data);
    return data.map((r, idx) => {
      return (
        <div key={idx}>
          <Space h="md" />
          <SurveyUserResponse
            userResponses={r}
            surveyId={surveyId}
            questions={questions}
            setOpened={setOpened}
            platform={platform}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
          <Space h="md" />
        </div>
      );
    });
  };
  //const hasErrors = () => survey.error || surveyResponses.error;
  if (error) {
    return <Text>Something went wrong</Text>;
  }

  console.log("VERBATIM Responses are", responseData);

  if (responseData && responseData.length > 0) {
    return (
      <>
        {dataCards(responseData)}

        {console.log("@# Next URL:", nextURL)}

        {nextURL ? (
          <Box sx={{ textAlign: "center" }}>
            <Text
              onClick={() => {
                fetchPaginatedResponses(
                  nextURL +
                    (searchParams.get("filterby_response") ||
                    searchParams.get("filterby_country") ||
                    searchParams.get("filterby_attribute")
                      ? `&${searchParams.toString()}`
                      : "")
                ).then(data => {
                  setResponseData([...responseData, ...data.results]);

                  setNextURL(data.next);
                });
              }}
              sx={{ cursor: "pointer", textDecorationLine: "underline" }}
              size="xs"
              color="blue"
            >
              Load more
            </Text>
            <Space h="lg" />
            <Space h="lg" />
          </Box>
        ) : null}
        {userResponses && (
          <Suspense fallback={<Loader />}>
            <RespondentDetailDrawer
              opened={searchParams.get("respondent")}
              userResponses={userResponses}
              surveyId={surveyId}
              setOpened={setOpened}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              questions={questions}
              owner={owner}
            />
          </Suspense>
        )}
      </>
    );
  } else {
    if (loader) {
      return (
        <Center>
          <Loader />
        </Center>
      );
    } else {
      return <NoData />;
    }
  }
});
