import React, { useState } from "react";
import { Text, Menu, Checkbox, Input, Tooltip, Button } from "@mantine/core";
import { useListState, randomId } from "@mantine/hooks";
import { arraySummariseWith } from "../../../../utils/helpers";
import style from "../../../../style.scss";

export default function AttributeStringValueSelector(props) {
  const { filterObject, attributeValues, dispatch, idx } = props;
  console.log("#st1 filterObject", filterObject);
  const [stringValue, setStringValue] = useState(filterObject.predicate);
  const [stringOpened, setStringOpened] = useState(false);
  const attributeValueData = () => {
    const initialArray = [];
    attributeValues &&
      attributeValues.map(a => {
        if (a === null) {
          initialArray.push({ value: "Null", label: "Null", checked: stringValue.includes("Null"), key: randomId() });
        } else {
          initialArray.push({ value: a, label: a.toString(), checked: stringValue.includes(a), key: randomId() });
        }
      });
    return initialArray;
  };
  const [values, handlers] = useListState(attributeValueData);
  const allChecked = values.every(value => value.checked);
  const indeterminate = values.some(value => value.checked) && !allChecked;

  console.log("#4e1.3 attributeValues in AttributeValueStringSelector", attributeValues);
  console.log("#4e1.4 filterObject in AttributeValueStringSelector", filterObject);

  function handleApplyfilter() {
    const collection = [];
    values.map(v => {
      console.log(v);
      if (v.checked) {
        collection.push(v.value);
      }
    });
    setStringValue(collection);
    dispatch({
      type: "updateFilter",
      filterType: "attributeFilter",
      index: idx,
      section: "predicate",
      value: collection,
    });
    setStringOpened(false);
  }

  const items = values.map((value, index) => (
    <Checkbox
      mt="xs"
      size="xs"
      label={value.label}
      key={value.key}
      value={value.value}
      checked={value.checked}
      onChange={event => {
        handlers.setItemProp(index, "checked", event.currentTarget.checked);
        if (event.target.checked) {
          setStringValue([...stringValue, event.target.value]);
        } else {
          setStringValue([...stringValue.filter(s => s !== event.target.value)]);
        }
      }}
    />
  ));

  return (
    <Menu
      classNames={style.filterDropdown}
      withinPortal
      position="bottom-end"
      shadow="sm"
      opened={stringOpened}
      onChange={setStringOpened}
    >
      <Menu.Target>
        <Tooltip
          disabled={stringValue.length == 0}
          multiline
          sx={{ fontSize: "1.2rem" }}
          width="23rem"
          withArrow
          //transitionProps={{ duration: 200 }}
          label={arraySummariseWith(stringValue, " or ")}
        >
          <Text
            className={`${style.filterHighlighter} ${style.filterValue} ${stringOpened && style.inputHighlight}`}
            size={14}
          >
            {console.log("#4e1.5", stringValue)}
            {stringValue.length > 0 ? arraySummariseWith(stringValue, " or ") : "Select value"}
          </Text>
        </Tooltip>
      </Menu.Target>

      <Menu.Dropdown className={style.filterDropdown}>
        <Input mb={20} placeholder="Search ..." />
        <Checkbox
          checked={allChecked}
          size="xs"
          indeterminate={!allChecked}
          label="Select All"
          transitionDuration={0}
          onChange={() => {
            handlers.setState(current => current.map(value => ({ ...value, checked: !allChecked })));
            //handleCheckAll();
          }}
        />

        {items}
        <Button mt={20} fullWidth disabled={!allChecked && !indeterminate} onClick={handleApplyfilter}>
          Add
        </Button>
      </Menu.Dropdown>
    </Menu>
  );
}
