import React, { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../../../../components/ErrorFallback";
import * as Sentry from "@sentry/react";
import { Table } from "@mantine/core";
import { fetchTaggedResponses, fetchPaginatedResponses } from "../../../../../api/Survey";
import { API_URLS } from "../../../../../utils/constants";
import { Text, Center, Loader, Box, Space } from "@mantine/core";
import { useIntl } from "react-intl";

export const VerbatimReport = memo(function VerbatimReport(props) {
  const { duration, searchParams, surveyId, dateRange, questionId } = props;
  const [verbatimData, setVerbatimData] = useState([]);

  // const [opened, setOpened] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [nextURL, setNextURL] = useState();

  const intl = useIntl();

  useEffect(() => {
    fetchTaggedResponses(API_URLS.GET_TAGGED_RESPONSES(surveyId, dateRange, questionId), {
      duration: duration,
      verbatim: "any",
      value: "all",
      ...(searchParams &&
        searchParams.get("filterby_response") && { filterby_response: searchParams.get("filterby_response") }),
      ...(searchParams &&
        searchParams.get("filterby_country") && { filterby_country: searchParams.get("filterby_country") }),
    })
      .then(data => {
        console.log("#13 data in fetchTaggedResponses", data);
        setVerbatimData(data.results);
        setNextURL(data.next);
      })
      .catch(error => {
        setError(error);
      })
      .finally(setLoader(false));
  }, []);

  console.log("#13 VarbatimReport", verbatimData);
  const verbatimElements =
    verbatimData &&
    verbatimData.map(res => {
      return { answer: res.answer };
    });

  const verbatimRows =
    verbatimElements &&
    verbatimElements.map((element, index) => (
      <tr key={index} style={{ cursor: "pointer" }}>
        <td>{element["answer"]}</td>
      </tr>
    ));

  console.log("~verbatimElements", verbatimElements);

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <>
      {loader ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
          onError={error => {
            // log the error to your error reporting service
            Sentry.captureException(error);
          }}
        >
          <Table striped highlightOnHover withBorder>
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: "common.responses", defaultMessage: "Responses" })}</th>
              </tr>
            </thead>
            <tbody>{verbatimRows}</tbody>
          </Table>
          {verbatimRows && nextURL && (
            <Box sx={{ textAlign: "center" }}>
              <Space h="md" />
              <Text
                onClick={() => {
                  fetchPaginatedResponses(nextURL).then(data => {
                    setVerbatimData([...verbatimData, ...data.results]);

                    setNextURL(data.next);
                  });
                }}
                sx={{ cursor: "pointer", textDecorationLine: "underline" }}
                size="xs"
                color="blue"
              >
                {intl.formatMessage({ id: "common.load_more", defaultMessage: "Load more" })}
              </Text>
            </Box>
          )}
        </ErrorBoundary>
      )}
    </>
  );
});
