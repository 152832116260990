import React, { useEffect, useReducer, useState, useCallback } from "react";
import { Group, Space, Button, Box, Grid, Alert, Stepper, Card, Divider, Tabs, Text, Flex } from "@mantine/core";
import { StyledTextInput } from "./../../../../components/MantineOverride";
import PreviewSurvey from "../../PreviewSurvey";
//import { ThankYou } from '../';
import {
  IconAlertCircle,
  IconChevronRight,
  IconChevronLeft,
  IconUsersGroup,
  IconUserCheck,
  IconClipboard,
  IconDevices,
  IconCheck,
  IconClipboardCheck,
  IconShieldCheck,
  IconRocket,
  IconBellRinging,
} from "@tabler/icons-react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import SurveyReducer from "./../../../../reducers/SurveyReducer";
import { AddQuestionMenu } from "./../../components";
import API from "./../../../../api/API";
import { capitalize, cleanSurvey, pluralize } from "./../../../../utils/helpers";
import { Questions, SurveySettings, SurveyPlatform } from "./../form";
import { decamelizeKeys, camelizeKeys } from "humps";
import { useAuthState } from "./../../../../context/AuthContext";
import { showErrorNotification, showSuccessNotification } from "../../../../components/notifications";
import LanguageSelector from "../../../../components/LanguageSelector";
import { showIntroduction } from "./../../../../utils/helpers";

import {
  Introduction,
  AudiencePreview,
  SurveyLinkModal,
  SurveyEmailModal,
  Notifications,
  ThankYou,
} from "./../../components";

import Style from "../../../../style.scss";
import { platformEmailTag } from "../../../../utils/config";
import { useIntl, FormattedMessage } from "react-intl";
import { Audience } from "./Audience";

//-----------------------------SurveyForm.js-----------------------------//

export function SurveyForm({ survey, editSurveyId }) {
  const { surveyId, section } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  console.log("tab", section);

  const rootPath = pathname.split("/").splice(0, 4).join("/");
  // const rootPath = pathname.split('/').pop().join('/');

  const [surveyLinkModalOpened, setSurveyLinkModalOpened] = useState(false);
  const [surveyEmailModalOpened, setSurveyEmailModalOpened] = useState(false);
  const [newSurveyData, setNewSurveyData] = useState(survey);
  const [formState, dispatch] = useReducer(SurveyReducer, camelizeKeys(newSurveyData));
  const [activeTab, setActiveTab] = useState("Settings");
  const [activeMainTab, setActiveMainTab] = useState("platform");
  const [currentQuestion, setCurrentQuestion] = useState();
  const [accordionItem, setAccordionItem] = useState();
  const [openIntro, setOpenIntro] = useState(true);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const currentMainTab = rootPath.includes("edit") ? section : activeMainTab;

  const auth = useAuthState();
  const csrfToken = auth.csrftoken;
  const currentProduct = auth.currentProduct || auth.organization.products[0];
  console.log("FormState", formState);

  console.log("auth in SurveyForm is", auth);
  console.log("!!! survey in surveyForm is", formState);

  useEffect(() => {
    //handlers.setState(formState.questions);
    //this is effect is to synchronise formState update
    //Ref: https://samuelsson.dev/update-state-with-usereducer-synchronously/

    console.log("stata form State is", formState);
  }, [formState, formState.surveyOptions]);

  useEffect(() => {
    Object.assign(formState["surveyOptions"], auth.organization.surveySettings);
  }, [auth.organization.surveySettings]);

  const props = {
    dispatch: dispatch,
    formState: formState,
    handleChange: handleInputChange,
    prodToken: currentProduct ? currentProduct.prodToken : auth.organization.products[0].prodToken,
    productName: currentProduct ? currentProduct.name : auth.organization.products[0].name,
    setCurrentQuestion: setCurrentQuestion,
    csrfToken: csrfToken,
    surveyId: surveyId,
  };

  const isLastStep = currentMainTab === "notifications";

  function handleTabChange(value) {
    if (pathname.includes("edit")) {
      navigate(rootPath + "/" + value + "/");
      setActiveMainTab(value);
    } else {
      handleSubmit(event, 0);
      setActiveMainTab(value);
    }
  }

  console.log("$99.0 activeMainTab", rootPath);
  function handleTabNext(event) {
    console.log("$99.1 activeMainTab", currentMainTab);

    switch (currentMainTab) {
      case "platform":
        if (!checkError("platform")) {
          handleSubmit(event, 0);
          setActiveMainTab("questions");
        }
        break;
      case "questions":
        if (!checkError("questions")) {
          setActiveMainTab("audience");
          handleSubmit(event, 0);
          navigate(rootPath + "/audience/");
        }

        break;
      case "audience":
        if (!checkError("audience")) {
          setActiveMainTab("notifications");
          handleSubmit(event, 0);
          navigate(rootPath + "/notifications/");
        }

        break;
      case "notifications":
        handleSubmit(event, 1);
        break;
      default:
        break;
    }
  }

  function handlePrevTab() {
    switch (currentMainTab) {
      case "platform":
        break;
      case "questions":
        navigate(rootPath + "/platform/");
        setActiveMainTab("platform");

        break;
      case "audience":
        setActiveMainTab("questions");
        navigate(rootPath + "/questions/");

        break;
      case "notifications":
        setActiveMainTab("audience");
        navigate(rootPath + "/audience/");
        break;
      default:
        break;
    }
  }

  function checkError(tab) {
    switch (tab) {
      case "questions":
        if (formState.questions.length == 0) {
          showErrorNotification(
            intl.formatMessage({
              id: "message.no_question",
              defaultMessage: "[Survey] Please add a question to the survey.",
            }),
            intl.formatMessage({
              id: "common.error",
              defaultMessage: "Error",
            })
          );
          return true;
        } else if (
          formState.questions.length > 0 &&
          !formState.questions.filter(q => q.questionType !== 30).every(q => q.questionText.length > 1)
        ) {
          showErrorNotification(
            intl.formatMessage({
              id: "message.incomplete_questions",
              defaultMessage: "[Survey] Please complete all the questions.",
            }),
            intl.formatMessage({
              id: "common.error",
              defaultMessage: "Error",
            })
          );
          return true;
        }
        return false;
      case "audience":
        if (formState.state == 1) {
          return false;
        } else {
          if (formState.platform !== "web" && !formState.surveyOptions.identifyRespondents) {
            showErrorNotification(
              intl.formatMessage({
                id: "message.identifyUserNotSet",
                defaultMessage: "[Audience] Please select if you want to identify multiple responses.",
              }),
              intl.formatMessage({
                id: "common.error",
                defaultMessage: "Error",
              })
            );
            return true;
          } else if (
            formState.platform !== "web" &&
            formState.surveyOptions.identifyRespondents == "true" &&
            !formState.surveyOptions.userIdentifierKey
          ) {
            showErrorNotification(
              intl.formatMessage({
                id: "message.userIdentifierNotSet",
                defaultMessage: "[Audience] Please select a user identifier key.",
              }),
              intl.formatMessage({
                id: "common.error",
                defaultMessage: "Error",
              })
            );
            return true;
          }
        }

        return false;
      default:
        return false;
    }
  }

  function formHasAnyErrors() {
    if (!checkError("platform") && !checkError("questions") && !checkError("audience")) {
      return false;
    }
    return true;
  }

  async function handleSubmit(event, activate, justSave = false) {
    // console.log("@@@@ submitting form", formState);
    event.preventDefault();
    setLoading(true);
    const cleanedData = cleanSurvey(formState);

    const requestMethod = editSurveyId ? "put" : "post";
    const postURL = editSurveyId ? `surveys/${editSurveyId}/` : `surveys/${currentProduct.prodToken}/`;
    //ref: https://stackoverflow.com/questions/6737840/call-javascript-object-method-with-a-variable

    let response;

    if (activate == 1) {
      if (!formHasAnyErrors()) {
        response = await API[requestMethod](postURL, decamelizeKeys(cleanedData), csrfToken, {
          activate: activate,
        });
      }
    } else {
      response = await API[requestMethod](postURL, decamelizeKeys(cleanedData), csrfToken, {
        activate: activate,
      });
    }
    if (response && response.success) {
      setNewSurveyData(response.success.data);
      if (activate == 1) {
        if (formState.platform == "link") {
          setSurveyLinkModalOpened(true);
        } else if (formState.platform == "email") {
          setSurveyEmailModalOpened(true);
        } else {
          navigate(`/surveys/${response.success.data.id}/responses/report/`);
        }
      } else if (activate == 0) {
        if (currentMainTab === "platform") {
          navigate(`/surveys/${response.success.data.id}/edit/questions/`);
        } else if (justSave) {
          showSuccessNotification(
            intl.formatMessage({
              id: "message.survey_saved",
              defaultMessage: "Survey saved successfully.",
            }),
            "Success"
          );
        }
      }
    } else if (response && response.error) {
      showErrorNotification(
        intl.formatMessage({
          id: "message.survey_not_saved",
          defaultMessage: "An error occurred while saving the survey.",
        }),
        "Error"
      );
    }

    // console.log(JSON.stringify(decamelizeKeys(cleanedData)));
  }

  function handleQuestionAdd(event, position, qType, mechanism) {
    // console.log("handleQuestionAdd", mechanism);
    event.preventDefault();
    dispatch({
      type: mechanism,
      qType: qType,
      index: position,
      productName: currentProduct.name,
    });
  }

  function nextButtonText() {
    if (isLastStep) {
      return intl.formatMessage({ id: "survey.launch", defaultMessage: "Launch" });
    } else {
      return intl.formatMessage({ id: "common.continue", defaultMessage: "Continue" });
    }
  }

  function handleInputChange(idx = null, event) {
    console.log("input change index is", event.target.value);

    dispatch({
      type: "inputChange",
      index: idx,
      targetName: event.target.name,
      targetId: event.target.id,
      value: event.target.value,
    });
  }

  function handleIntroDelete() {
    dispatch({ type: "removeIntroduction" });
    setAccordionItem(null);
    setAccordionItem("q0");
    setCurrentQuestion(0);
  }

  return (
    <>
      <Box>
        {/* <Box sx={{ paddingRight: 'calc(var(--mantine-aside-width, 200px) + 16px)' }}> */}
        {console.log("form state iz", formState)}

        <form>
          <Group position="apart" sx={{ alignItems: "end" }}>
            <StyledTextInput
              placeholder={intl.formatMessage({ id: "survey.name_placeholder", defaultMessage: "Survey name" })}
              name="surveyName"
              label={intl.formatMessage({ id: "survey.name_label", defaultMessage: "Name your Survey" })}
              value={formState && formState.title}
              onChange={e => handleInputChange(0, e)}
              sx={{ width: "48%" }}
            />
            <Group spacing="xs" position="right" sx={{ gap: 0 }}>
              <Link to="/surveys">
                <Button variant="outline">{intl.formatMessage({ id: "common.close", defaultMessage: "Close" })}</Button>
              </Link>
            </Group>
          </Group>
          <Space h="md" />

          <Grid gutter="sm">
            <Grid.Col span={7} sx={{ position: "relative" }}>
              <Space h="sm" />
              <Flex direction="column" sx={{ minHeight: 0, height: "100vh" }}>
                <Box sx={{ flex: 1, overflowY: "auto" }}>
                  <Tabs
                    value={rootPath.includes("edit") ? section : activeMainTab}
                    onTabChange={(event, val) => handleTabChange(event, val)}
                    sx={{ height: "100%" }}
                    keepMounted={false}
                  >
                    <Tabs.List>
                      <Tabs.Tab value="platform" rightSection={<IconChevronRight size={16} stroke={1} />}>
                        {intl.formatMessage({ id: "survey.platform", defaultMessage: "Platform" })}
                      </Tabs.Tab>
                      <Tabs.Tab value="questions" rightSection={<IconChevronRight size={16} stroke={1} />}>
                        {intl.formatMessage({ id: "common.survey", defaultMessage: "Survey" })}
                      </Tabs.Tab>
                      <Tabs.Tab value="audience" rightSection={<IconChevronRight size={16} stroke={1} />}>
                        {intl.formatMessage({ id: "survey.audience", defaultMessage: "Audience" })}
                      </Tabs.Tab>
                      <Tabs.Tab value="notifications">
                        {intl.formatMessage({ id: "survey.notifications", defaultMessage: "Notifications" })}
                        <Text span size={12} color="dimmed">
                          &nbsp;(Optional)
                        </Text>
                      </Tabs.Tab>
                    </Tabs.List>
                    <Tabs.Panel value="platform" pt="xs">
                      <SurveyPlatform {...props} />
                      <Space h="md" />
                    </Tabs.Panel>
                    <Tabs.Panel value="questions" pt="xs">
                      <Space h="md" />
                      {formState.state == 1 && (
                        <>
                          <Alert icon={<IconAlertCircle size={16} />} title="Editing is limited." color="red">
                            {intl.formatMessage({
                              id: "survey.limited_editing",
                              defaultMessage:
                                "This survey is live. You can only change the language. If you want to make other changes , we suggest that you mark this survey complete and start a new one.",
                            })}
                          </Alert>
                          <Space h="md" />
                        </>
                      )}
                      <Group position="apart" align="flex-end">
                        <LanguageSelector
                          currentLang={formState.languageCode || "en"}
                          dispatch={dispatch}
                          from="survey"
                        />
                        <AddQuestionMenu
                          handleQuestionAdd={handleQuestionAdd}
                          platform={formState.platform}
                          questions={formState.questions}
                          mechanism="addQuestion"
                          dispatch={dispatch}
                          intl={intl}
                        />
                      </Group>

                      {showIntroduction(formState) && (
                        <Introduction
                          key={JSON.stringify(formState.surveyOptions.introduction)}
                          details={formState.surveyOptions.introduction}
                          setCurrentQuestion={setCurrentQuestion}
                          handleDelete={handleIntroDelete}
                          accordionItem={accordionItem}
                          opened={openIntro}
                          setOpened={setOpenIntro}
                          setAccordionItem={setAccordionItem}
                          //showIntroduction={formState.surveyOptions.showIntroduction}
                          dispatch={dispatch}
                        />
                      )}
                      {console.log("@@@@@ formState before rendering questions", props)}

                      <Questions
                        {...props}
                        accordionItem={accordionItem}
                        setAccordionItem={setAccordionItem}
                        setOpenIntro={setOpenIntro}
                      />
                      {formState.surveyOptions.thankyou && (
                        <ThankYou
                          dispatch={dispatch}
                          details={formState.surveyOptions.thankyou}
                          setCurrentQuestion={setCurrentQuestion}
                        />
                      )}
                      <Space h="md" />
                    </Tabs.Panel>
                    <Tabs.Panel value="audience" pt="xs">
                      {formState.platform == "email" || formState.platform == "link" ? (
                        <Audience {...props} />
                      ) : (
                        <SurveySettings {...props} />
                      )}
                      <Space h="md" />
                    </Tabs.Panel>
                    <Tabs.Panel value="notifications" pt="xs">
                      <Notifications
                        options={formState.surveyOptions}
                        platform={formState.platform}
                        questions={camelizeKeys(newSurveyData.questions)}
                        surveyId={formState.id}
                        csrfToken={csrfToken}
                        surveyState={camelizeKeys(newSurveyData)}
                      />
                    </Tabs.Panel>
                  </Tabs>
                </Box>

                <div className={Style.stickyNext}>
                  <Card sx={{ border: "1px solid #ededed", boxShadow: "rgba(0, 0, 0, 0.24) 0px -1px 5px" }}>
                    <Group position="apart">
                      <Button
                        leftIcon={<IconChevronLeft />}
                        variant="subtle"
                        onClick={handlePrevTab}
                        disabled={currentMainTab == "platform"}
                      >
                        Back
                      </Button>
                      <Group>
                        <Button variant="subtle" onClick={e => handleSubmit(e, 0, true)}>
                          Save
                        </Button>
                        <Button
                          variant={isLastStep ? "filled" : "outline"}
                          rightIcon={<IconChevronRight />}
                          onClick={e => handleTabNext(e)}
                          disabled={isLastStep && formState.questions.length == 0}
                        >
                          {nextButtonText()}
                        </Button>
                      </Group>
                    </Group>
                  </Card>
                </div>
              </Flex>
            </Grid.Col>
            <Grid.Col span={5}>
              <PreviewSurvey
                prodToken={currentProduct.prodToken}
                survey={decamelizeKeys(cleanSurvey(formState))}
                currentQuestion={currentQuestion}
                setCurrentQuestion={setCurrentQuestion}
                tab={activeTab}
              />
            </Grid.Col>
          </Grid>
        </form>
        <Space h="md" />
      </Box>
      <SurveyLinkModal
        opened={surveyLinkModalOpened}
        setOpened={setSurveyLinkModalOpened}
        survey={newSurveyData}
        token={currentProduct.prodToken}
        userIdentifierKey={formState.surveyOptions.userIdentifierKey}
        emailPlatform={formState.surveyOptions.email?.platform}
      />
      {console.log("newSurveyData", newSurveyData)}
      {newSurveyData.platform == "email" && (
        <SurveyEmailModal
          opened={surveyEmailModalOpened}
          setOpened={setSurveyEmailModalOpened}
          survey={newSurveyData}
          token={currentProduct.prodToken}
        />
      )}
    </>
  );
}
