import React, { useEffect } from "react";
import { useAPICall } from "../../../../hooks";
import { createStyles, Group, Paper, Text, Center, Loader, Stack, Tooltip as TT, Grid, Popover } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import API from "../../../../api/API";
import { API_URLS } from "../../../../utils/constants";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useIntl } from "react-intl";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);

const useStyles = createStyles(theme => ({
  root: {
    padding: `calc(${theme.spacing.xl} * 1.5)`,
    //height: "90px",
  },

  value: {
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color: theme.colorScheme === "dark" ? theme.colors.dark[3] : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

export function SurveyStats({ surveyId, timeframe, dateRange, platform, searchParams }) {
  const { classes } = useStyles();
  const intl = useIntl();
  // console.log("dateRange in STATS", dateRange);

  // console.log("tsurveyId in STATS", surveyId);

  const [surveyStats, getSurveyStats] = useAPICall(() =>
    API.get(
      API_URLS.GET_SURVEY_STATS(surveyId, timeframe, dateRange) +
        `${
          searchParams.get("filterby_response") || searchParams.get("filterby_country")
            ? `&${searchParams.toString()}`
            : ""
        }`
    )
  );
  const { data, error, loading } = surveyStats;

  const respondentCountries = data && data.success.data.countries;

  useEffect(() => {
    if (dateRange && dateRange.every(d => d !== null)) {
      getSurveyStats();
    }
  }, [dateRange, searchParams.get("filterby_response"), searchParams.get("filterby_country")]);

  // console.log('surveyStat', data && data.success.data);
  console.log("surveyStat", data);

  const responseRate =
    Math.round((data && data.success.data.answered / (data && data.success.data.respondents)) * 100) || 0;
  const completionRate =
    Math.round((data && data.success.data.completed / (data && data.success.data.respondents)) * 100) || 0;

  const dat = [
    platform !== "email" && {
      title: intl.formatMessage({ id: "reports.unique_views", defaultMessage: "Unique Views" }),
      info: intl.formatMessage({
        id: "reports.unique_views_desc",
        defaultMessage: "Number of unique users who saw the survey at least once.",
      }),
      value: data && data.success.data.respondents,
    },
    {
      title: intl.formatMessage({ id: "reports.answered", defaultMessage: "Answered" }),
      info: intl.formatMessage({
        id: "reports.answered_desc",
        defaultMessage: "Number of unique users who answered at least one question.",
      }),
      value: data && data.success.data.answered,
    },
    {
      title: intl.formatMessage({ id: "reports.completed", defaultMessage: "Completed" }),
      info: intl.formatMessage({
        id: "reports.completed_desc",
        defaultMessage: "Number of unique users who answered all the questions.",
      }),
      value: data && data.success.data.completed,
    },
    platform !== "email" && {
      title: intl.formatMessage({ id: "reports.response_rate", defaultMessage: "Response Rate" }),
      info: intl.formatMessage({
        id: "reports.response_rate_desc",
        defaultMessage: "Percentage of unique users who answered at least one question.",
      }),
      value: `${responseRate}%`,
    },
    platform !== "email" && {
      title: intl.formatMessage({ id: "reports.completion_rate", defaultMessage: "Completion Rate" }),
      info: intl.formatMessage({
        id: "reports.completion_rate_desc",
        defaultMessage: "Percentage of unique users who completed all the questions.",
      }),
      value: `${completionRate}%`,
    },
  ];

  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

  console.log("data", dat);

  const stats = dat.map(stat => {
    if (stat) {
      return (
        <Paper key={stat.title} withBorder p="md" radius="md">
          <Stack spacing="xs" sx={{ alignItems: "center" }}>
            <Group position="apart" spacing="5px">
              <Text size="xs" color="dimmed" className={classes.title}>
                {stat.title}
              </Text>
              <TT label={stat.info} withArrow>
                <IconInfoCircle className={classes.icon} size="1.6rem" stroke={1.5} />
              </TT>
            </Group>

            <Text className={classes.value}>{stat.value}</Text>
          </Stack>
        </Paper>
      );
    }
  });

  if (error) {
    return <Text>{"Something went wrong."}</Text>;
  }

  return (
    <div className={classes.root}>
      <Grid>
        {loading ? (
          <Center>
            <Loader />
          </Center>
        ) : (
          <Stack>
            <Group noWrap={false} style={{ minWidth: 200 }}>
              {stats}
              {respondentCountries && respondentCountries.length > 0 && (
                <Paper withBorder p="sm" radius="md">
                  <Stack spacing="0">
                    <Text size="xs" color="dimmed" className={classes.title}>
                      {intl.formatMessage({
                        id: "reports.country_stat_heading",
                        defaultMessage: "Most Responses From",
                      })}
                    </Text>

                    {respondentCountries.slice(0, 1).map((country, idx) => {
                      return (
                        <Text key={idx} style={{ textAlign: "center" }}>
                          <b>{country.country == null ? "Not set" : regionNames.of(country.country)}</b>
                          <Popover width={600} position="bottom" radius="md" withArrow shadow="md">
                            <Popover.Target>
                              <Text
                                size="xs"
                                color="dimmed"
                                variant="link"
                                style={{
                                  cursor: "pointer",
                                  lineHeight: 1,
                                  display: "block",
                                  align: "center",
                                }}
                              >
                                {intl.formatMessage({ id: "reports.country_stat_help", defaultMessage: "View more" })}
                              </Text>
                            </Popover.Target>
                            <Popover.Dropdown>
                              <Paper withBorder p="md" radius="md">
                                <Text size="xs" color="dimmed" className={classes.title}>
                                  {intl.formatMessage({
                                    id: "reports.country_stat_subheading",
                                    defaultMessage: "Respondents by Country (Top 5)",
                                  })}
                                </Text>
                                <CountryChart countries={data.success.data.countries} />
                              </Paper>
                            </Popover.Dropdown>
                          </Popover>
                        </Text>
                      );
                    })}
                  </Stack>
                </Paper>
              )}
            </Group>
          </Stack>
        )}
      </Grid>
    </div>
  );
}

function CountryChart(props) {
  const { countries } = props;
  const intl = useIntl();
  const topCountries = countries.slice(0, 5);
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });
  const labels = topCountries.map(c => (c.country == null ? "Not set" : regionNames.of(c.country)));
  const countryCount = countries.map(c => {
    if (c.country == null) {
      return c.notset;
    } else {
      return c.count;
    }
  });
  const pieOptions = {
    responsive: true,
    layout: {
      padding: {
        top: 5,
        left: 10,
        right: 10,
        bottom: 10,
      },
    },
    plugins: {
      datalabels: {
        color: "#fff",
        align: "center",
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map(data => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage; //value + " (" + percentage + ")";
        },
      },
      legend: {
        position: "right",
        labels: {
          usePointStyle: true,
          useBorderRadius: true,
          borderRadius: 4,
        },
      },
      colors: {
        forceOverride: true,
      },
    },
  };
  const data = {
    labels: labels,
    datasets: [
      {
        label: intl.formatMessage({ id: "common.responses", defaultMessage: "Responses" }),
        data: countryCount,
        // backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
        // borderColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)", "rgb(255, 205, 86)"],
        borderWidth: 1,
        radius: "160",
        cutout: "50%",
      },
    ],
  };

  return (
    <div style={{ position: "relative", height: "450px", width: "450px" }}>
      <Doughnut data={data} options={pieOptions} />
    </div>
  );
}
