import React from "react";

import AuthenticatedRoute from "./layouts/authenticated/AuthenticatedRoute";
import UnauthenticatedRoute from "./layouts/unauthenticated/UnauthenticatedRoute";
import Page404 from "./pages/Page404";

import SurveyOutlet from "./pages/SurveyOutlet";
import Survey from "./pages/survey/Survey";
import SurveyList from "./pages/surveylist/SurveyList";

import NewSurvey from "./pages/survey/NewSurvey";
import EditSurvey from "./pages/survey/EditSurvey";
import SurveyResponse from "./pages/responses/SurveyResponse";

import { Login, Signup } from "./pages/auth";
import { Organization, Settings, Team } from "./pages/account";
import { createBrowserRouter } from "react-router-dom";
import Connect from "./pages/connect/Connect";
import ForgotPassword from "./pages/auth/passwordreset/ForgotPassword";
import ResetPassword from "./pages/auth/passwordreset/ResetPassword";
import ResetDone from "./pages/auth/passwordreset/ResetDone";
import AccountActivated from "./pages/account/team/AccountActivated";
import AcceptTeamInvite from "./pages/account/team/AcceptTeamInvite";
import Data from "./pages/data/Data";
import { UserAttributes } from "./pages/data/UserAttributes";
import Slack from "./pages/integrations/Slack";
import Webhook from "./pages/integrations/Webhook";
import Zapier from "./pages/integrations/Zapier";
import Integrations from "./pages/integrations";

import * as Sentry from "@sentry/react";
const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <AuthenticatedRoute />,

    children: [
      // {
      //   index: true,
      //   element: <Dashboard />,
      // },
      {
        path: "connect",
        element: <Connect />,
      },
      {
        path: "respondents",
        element: <Data />,
      },
      {
        path: "attributes",
        element: <UserAttributes />,
      },
      {
        path: "integrations/",
        element: <Integrations />,
      },
      { path: "integrations/slack", element: <Slack /> },
      { path: "integrations/webhook", element: <Webhook /> },
      { path: "integrations/zapier", element: <Zapier /> },
      {
        path: "surveys/",
        element: <SurveyOutlet />,

        children: [
          { index: true, element: <SurveyList /> },
          { path: "new", element: <Survey />, handle: { restrict: ["Viewer"] } },
          { path: "new/:template", element: <NewSurvey />, handle: { restrict: ["Viewer"] } },
          {
            path: ":surveyId/edit/:section",
            element: <EditSurvey />,
            handle: { restrict: ["Viewer"] },
          },
          { path: ":surveyId/responses/:tab", element: <SurveyResponse /> },
        ],
      },
      { path: "account/sites", element: <Organization /> },
      { path: "account/settings", element: <Settings /> },
      { path: "account/team", element: <Team /> },
    ],
  },
  {
    element: <UnauthenticatedRoute />,
    children: [
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
      {
        path: "/reset-password/:token/success",
        element: <ResetDone />,
      },
      { path: "invite/:token", element: <AcceptTeamInvite /> },
      { path: "invite/:token/success", element: <AccountActivated /> },
    ],
  },
  { path: "*", element: <Page404 /> },
]);
!(function () {
  try {
    var e =
        "undefined" != typeof window
          ? window
          : "undefined" != typeof global
          ? global
          : "undefined" != typeof self
          ? self
          : {},
      n = new Error().stack;
    n &&
      ((e._sentryDebugIds = e._sentryDebugIds || {}), (e._sentryDebugIds[n] = "937cb7c8-5676-59fd-a853-0d4dfafd1af1"));
  } catch (e) {}
})();
//# debugId=937cb7c8-5676-59fd-a853-0d4dfafd1af1
