import React from "react";
import { Menu, Button, Text, Group, Divider, UnstyledButton } from "@mantine/core";
import {
  IconPlus,
  IconArrowsLeftRight,
  IconMoodHappy,
  Icon123,
  IconClipboardCheck,
  IconEdit,
  IconClipboardList,
  IconExternalLink,
  IconAt,
} from "@tabler/icons-react";
import style from "./../../../style.scss";

export function AddQuestionMenu(props) {
  const { handleQuestionAdd, platform, questions, index, mechanism, intl, dispatch } = props;

  return (
    <Menu
      shadow="md"
      width={300}
      position="bottom-start"
      transitionDuration={0}
      styles={() => ({
        itemLabel: {
          fontSize: "1.6rem",
        },
      })}
    >
      {mechanism === "addQuestion" ? (
        <Menu.Target>
          <Button variant="outline">
            {intl.formatMessage({ id: "survey.add_element", defaultMessage: "Add Element" })}
          </Button>
        </Menu.Target>
      ) : (
        <Divider
          my="xs"
          variant="dashed"
          labelPosition="right"
          label={
            <Menu.Target>
              <UnstyledButton>
                <Group spacing={2}>
                  <IconPlus size={16} />
                  <Text size={14}>
                    {intl.formatMessage({ id: "survey.add_element_here", defaultMessage: "Add Element here" })}
                  </Text>
                </Group>
              </UnstyledButton>
            </Menu.Target>
          }
        />
      )}
      <Menu.Dropdown>
        <Menu.Item
          disabled={platform === "email"}
          icon={<IconClipboardCheck size={14} />}
          onClick={() => dispatch({ type: "addIntroduction" })}
        >
          {intl.formatMessage({ id: "survey.introduction", defaultMessage: "Introduction" })}
        </Menu.Item>
        <Menu.Label>{intl.formatMessage({ id: "common.questions", defaultMessage: "Questions" })}</Menu.Label>
        <Menu.Item
          icon={<IconClipboardCheck size={14} />}
          onClick={e => handleQuestionAdd(e, index, "mcq_single", mechanism)}
        >
          {intl.formatMessage({ id: "survey.mcq_single", defaultMessage: "MCQ - Single Select" })}
        </Menu.Item>
        <Menu.Item
          icon={<IconClipboardList size={14} />}
          disabled={platform === "email" && questions.length == 0}
          className={style.menuLabel}
          onClick={e => handleQuestionAdd(e, index, "mcq_multi", mechanism)}
        >
          {intl.formatMessage({ id: "survey.mcq_multi", defaultMessage: "MCQ - Multi Select" })}
        </Menu.Item>
        <Menu.Item
          icon={<IconEdit size={14} />}
          disabled={platform === "email" && questions.length == 0}
          onClick={e => handleQuestionAdd(e, index, "open_text", mechanism)}
        >
          <Text>{intl.formatMessage({ id: "survey.open_text", defaultMessage: "Open Text" })}</Text>
        </Menu.Item>
        <Menu.Item icon={<IconMoodHappy size={14} />} onClick={e => handleQuestionAdd(e, index, "nps", mechanism)}>
          {intl.formatMessage({ id: "survey.nps", defaultMessage: "NPS" })}
        </Menu.Item>
        <Menu.Item
          icon={<Icon123 size={14} />}
          disabled={platform === "email" && questions.length == 0}
          onClick={e => handleQuestionAdd(e, index, "rating", mechanism)}
        >
          {intl.formatMessage({ id: "survey.rating", defaultMessage: "Rating" })}
        </Menu.Item>
        <Menu.Label>{intl.formatMessage({ id: "common.other", defaultMessage: "Other" })}</Menu.Label>

        <Menu.Item
          icon={<IconAt size={14} />}
          disabled={platform === "email" && questions.length == 0}
          onClick={e => handleQuestionAdd(e, index, "email", mechanism)}
        >
          {intl.formatMessage({ id: "survey.q_email", defaultMessage: "Email" })}
        </Menu.Item>
        <Menu.Item
          icon={<IconExternalLink size={14} />}
          disabled={index + 1 === questions.length || (platform === "email" && questions.length == 0)}
          onClick={e => handleQuestionAdd(e, index, "redirect", mechanism)}
        >
          {intl.formatMessage({ id: "survey.q_redirect", defaultMessage: "Redirect" })}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
