import React, { useEffect, useMemo, useState } from "react";
import { Text, Button, Paper, Anchor, Group, Menu } from "@mantine/core";
import { IconPlus, IconTrash, IconX, IconFilePencil, IconGlobe } from "@tabler/icons-react";
// import { useAPICall } from "../../hooks";

// import { getUserAttributes } from "../../api/Account";
// import checkDataType from "../../utils/checkDataType";

// import { useNavigate } from "react-router-dom";

import { FilterQuestion, FilterCountry } from "../../components/filters";
import { useIntl } from "react-intl";

// import { FilterAttribute } from "./components/filters/FilterAttribute";

function ResponseFilters({
  token,
  surveyId,
  csrftoken,
  filterState,
  dispatch,
  handleFilterSubmit,
  filterCount,

  questions,
  searchParams,
  setSearchParams,
  filterMatchesQueryParams,
}) {
  const intl = useIntl();
  // const [attribs, getAttribs] = useAPICall(() => getUserAttributes(token));

  const [showApplyButton, setShowApplyButton] = useState(false);

  // const { data, error, loading } = attribs;
  console.log("filterMatchesQueryParams 1", filterMatchesQueryParams);

  useEffect(() => {
    const allFilled =
      Object.keys(filterState.responseFilter).length > 0 &&
      filterState.responseFilter.every(f => f.qid !== "" && f.resp.length > 0);
    const countryFilled =
      Object.keys(filterState.countryFilter).length > 0 && filterState.countryFilter.every(f => f.code.length > 0);
    setShowApplyButton((allFilled || countryFilled) && !filterMatchesQueryParams);
  }, [filterState, searchParams.get("filterby_response"), searchParams.get("filterby_country")]);

  function handleAddfilter(filterType) {
    dispatch({
      filterType: filterType,
      type: "addFilter",
    });
  }

  function handleDelete(idx, filterType) {
    dispatch({ type: "deleteFilter", filterType: filterType, index: idx });
  }

  function handleClearFilters() {
    dispatch({ type: "clearAllFilters" });
    setSearchParams("");
  }

  // useEffect(() => {
  //   getAttribs();
  // }, []);
  // const attributes = useMemo(() => userAttribElements(data), [data]);

  // function userAttribElements(attribs) {
  //   console.log("Attribs from useAPICall is", attribs);
  //   var elements = [];
  //   if (attribs !== null) {
  //     for (const key in attribs) {
  //       elements.push({
  //         value: key,
  //         label: key,
  //         type: checkDataType(attribs[key]["value"]),
  //       });
  //     }
  //   }
  //   return elements;
  // }

  return (
    <>
      <Group sx={{ marginBottom: "2rem", marginTop: "1rem", borderBottom: "1px solid #ced4da" }} position="apart">
        <Text sx={{ fontSize: "1.6rem" }}>All Filters</Text>
      </Group>
      <Group position="apart">
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              leftIcon={<IconPlus />}
              //disabled={opened}

              variant="light"
              styles={() => ({
                inner: {
                  justifyContent: "space-between",
                },
                label: {
                  fontWeight: "400",
                  fontSize: "1.6rem",
                },
              })}
            >
              {intl.formatMessage({ id: "reports.all_filters" })}
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>{intl.formatMessage({ id: "reports.filter_by" })}</Menu.Label>
            <Menu.Item
              icon={<IconFilePencil size={16} stroke={1} />}
              onClick={() => {
                handleAddfilter("responseFilter");
                // setOpened(true);
              }}
            >
              {intl.formatMessage({ id: "filters.response" })}
            </Menu.Item>
            <Menu.Item
              icon={<IconGlobe size={16} stroke={1} />}
              onClick={() => {
                handleAddfilter("countryFilter");
                // setOpened(true);
              }}
            >
              {intl.formatMessage({ id: "filters.country" })}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Button
          variant="subtle"
          onClick={() => handleClearFilters()}
          disabled={filterCount === 0}
          sx={{ "&[data-disabled]": { backgroundColor: "transparent" } }}
        >
          <Text weight={400}>{intl.formatMessage({ id: "filters.clear" })}</Text>
        </Button>
      </Group>

      {console.log("@attributeFilter", filterState)}

      {/* {attributes &&
        filterState.attributeFilter.length > 0 &&
        filterState.attributeFilter.map((f, filterIndex) => {
          return (
            <Paper sx={{ marginTop: "20px", borderRadius: "8px" }} key={filterIndex} shadow="xs" p="md">
              {console.log("@AttributeFilter", f)}
              <FilterAttribute
                filterIndex={filterIndex}
                handleFilterRemove={handleFilterRemove}
                f={f}
                attributes={attributes}
                dispatch={dispatch}
                surveyId={surveyId}
                token={token}
                csrftoken={csrftoken}

                //handleSubmit={handleFilterSubmit}
              />
            </Paper>
          );
        })} */}
      {filterState.responseFilter &&
        filterState.responseFilter.map((f, filterIndex) => {
          return (
            <Paper shadow="xs" p="md" mt={20} key={filterIndex}>
              {filterCount > 1 && (
                <Group position="right">
                  <IconTrash
                    size={16}
                    style={{ cursor: "pointer" }}
                    stroke={1.5}
                    onClick={() => handleDelete(filterIndex, "responseFilter")}
                  />
                </Group>
              )}

              {console.log("@responseFilter", f)}

              <FilterQuestion
                searchParams={searchParams}
                index={filterIndex}
                filter={f}
                dispatch={dispatch}
                questions={questions}
                surveyId={surveyId}
                token={token}
                csrftoken={csrftoken}
              />
            </Paper>
          );
        })}
      {filterState.countryFilter &&
        filterState.countryFilter.map((f, filterIndex) => {
          return (
            <Paper shadow="xs" p="md" mt={20} key={filterIndex}>
              {filterCount > 1 && (
                <Group position="right">
                  <IconTrash
                    size={16}
                    style={{ cursor: "pointer" }}
                    stroke={1.5}
                    onClick={() => handleDelete(filterIndex, "countryFilter")}
                  />
                </Group>
              )}

              {console.log("@responseFilter", f)}

              <FilterCountry
                searchParams={searchParams}
                index={filterIndex}
                filter={f}
                dispatch={dispatch}
                surveyId={surveyId}
                token={token}
                csrftoken={csrftoken}
              />
            </Paper>
          );
        })}

      {showApplyButton && (
        <Button mt={20} onClick={handleFilterSubmit}>
          {intl.formatMessage({ id: "filters.apply" })}
        </Button>
      )}
    </>
  );
}

export default ResponseFilters;
