import React, { useState, useEffect } from "react";
import { Group, Text, Select, Space, ActionIcon, NumberInput, TextInput, Grid, Menu, Card } from "@mantine/core";
import { StyledSelect } from "../../../../components/MantineOverride";
import { DatePicker } from "@mantine/dates";
import { IconChevronDown, IconTrash } from "@tabler/icons-react";
import { getUserAttributes } from "./../../../../api/Account";
import { useAPICall } from "./../../../../hooks/useAPICall";
import checkDataType from "./../../../../utils/checkDataType";
import AttributeSelection from "./../AttributeSelection";
import { filterComparatorOptions } from "./../../../../utils/filterHelper";
import ValueSelector from "../../../../components/attributes/ValueSelector";
import { getAttributeValues } from "../../../../api/Survey";

//import API from '../../../api/API';

const attribDataType = {
  number: 1,
  string: 2,
  date: 3,
  boolean: 4,
};

const logicOptions = [
  { value: "gt", label: "is greater than", datatype: [1, 3] },
  { value: "gte", label: "is greater than or equal to", datatype: [1, 3] },
  { value: "lt", label: "is less than", datatype: [1, 3] },
  { value: "lte", label: "is less than or equal to", datatype: [1, 3] },
  { value: "eq", label: "is equal to", datatype: [1, 2, 3, 4] },
  { value: "neq", label: "is not equal to", datatype: [1, 2, 3, 4] },
  { value: "con", label: "contains", datatype: [2] },
  { value: "dcon", label: "does not contain", datatype: [2] },
  //{ value: 'skip', label: 'is skipped', qType: [1, 2, 3, 4, 5] },
];

export function AttributeFilter(props) {
  const { idx, token, dispatch, filter, filterCount, surveyId, csrfToken } = props;
  const [attribs, getAttribs] = useAPICall(getUserAttributes, token);
  const [attributeValues, setAttributeValues] = useState();
  const { data, error, loading } = attribs;

  const [operatorOpened, setOperatorOpened] = useState(false);

  function fetchAttributeValues(attr) {
    getAttributeValues({ attribute: attr }, surveyId, token, csrfToken).then(resp => {
      console.log("getAttributeValues", resp.data.values);
      if (resp.status === 200) {
        setAttributeValues(resp.data.values);
      } else {
        console.log("error");
      }
    });
  }

  useEffect(() => {
    if (surveyId && filter.property && filter.datatype === "string") {
      console.log("#911 filter", filter);
      fetchAttributeValues(filter.property);
    }
  }, [filter.property, filter.operator]);

  console.log("#911 filter", filter);

  useEffect(() => {
    console.log(typeof getAttribs);
    getAttribs();
  }, []);

  function handleFilterRemoval(idx) {
    dispatch({
      type: "removeFilter",
      filterIndex: idx,
    });
  }
  function filteredLogicOptions(options, datatype) {
    return options.filter(x => x.datatype.find(y => y === attribDataType[datatype]));
  }

  const attributeList = () => {
    console.log("--------->attrs--------->", data);
    const attrList = Object.keys(data);
    return attrList.map(a => ({
      value: a,
      label: a,
      data: data[a].value.toString(),
      datatype: checkDataType(data[a].value),
    }));
  };

  function handleChange(idx, section, val, datatype) {
    dispatch({
      type: "updateFilter",
      idx: idx,
      section: section,
      value: val,
      ...(section === "property" && {
        datatype: datatype,
      }),
    });
    // if (section === "property") {
    //   fetchAttributeValues(val);
    // }
  }

  if (loading) {
    return <div>loading....</div>;
  } else if (Object.keys(filter).length === 0) {
    return null;
  } else {
    console.log("Attribs in AttributeFilter", attribs);
    return (
      <>
        {idx > 0 ? (
          <Group>
            <Text size="14px" color="dimmed">
              and
            </Text>
          </Group>
        ) : null}
        <Group>
          <Text size={16}>{idx + 1}.</Text>

          <AttributeSelection
            attributeList={attributeList}
            idx={idx}
            handleChange={handleChange}
            filter={filter}
            filterCount={filterCount}
          />

          {filter.property && filter.property.length > 0 ? (
            <Menu position="bottom-start" shadow="md" width={300} opened={operatorOpened} onChange={setOperatorOpened}>
              <Menu.Target>
                <Card withBorder radius="xs" px={10} py={6}>
                  <Group>
                    <Text size={16}>
                      {filter.operator ? (
                        filterComparatorOptions[filter.datatype].operators.find(o => o.value === filter.operator)?.label
                      ) : (
                        <Text color="dimmed">Pick one</Text>
                      )}
                    </Text>
                    <IconChevronDown size={16} color="rgb(134, 142, 150)" />
                  </Group>
                </Card>
              </Menu.Target>

              <Menu.Dropdown>
                {filterComparatorOptions[filter.datatype].operators
                  .filter(item => item.value !== "notset")
                  .map((o, operatorIndex) => {
                    return (
                      <Menu.Item onClick={() => handleChange(idx, "operator", o.value)} key={operatorIndex}>
                        {o.label}
                      </Menu.Item>
                    );
                  })}
              </Menu.Dropdown>
            </Menu>
          ) : null}

          {filter.operator.length > 0 ? (
            <ValueSelector
              idx={idx}
              filterObject={filter}
              dispatch={dispatch}
              handleChange={handleChange}
              attributeValues={attributeValues}
              source="survey"
            /> //renderPredicate(attributeList().find(a => a.value === filter.property)?.datatype)
          ) : null}

          <ActionIcon>
            <IconTrash size={18} onClick={() => handleFilterRemoval(idx)} />
          </ActionIcon>
        </Group>

        <Space h="sm" />
      </>
    );
  }
}
