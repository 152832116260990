import APIBase from "./APIBase";
import API from "./API";
import { API_URLS } from "../utils/constants";
import { exportData } from "../utils/helpers";
import dayjs from "dayjs";

export async function postSurvey(requestData, csrfToken) {
  let body = JSON.stringify(requestData);
  console.log("in post function", body);

  let response = await APIBase.post(API_URLS.SURVEYS, body, {
    headers: { "X-CSRFTOKEN": csrfToken },
  });
  if (response.status !== 201) {
    console.log(response);
  } else {
    return response;
  }
}

export async function getAttributeValues(requestData, surveyId, token, csrfToken) {
  let body = JSON.stringify(requestData);
  let response = await APIBase.post(API_URLS.GET_RESPONDENT_ATTRIBUTE_VALUES(token, surveyId), body, {
    headers: { "X-CSRFTOKEN": csrfToken },
  });

  console.log("$$$$$ Response $$$$", response);
  return response;
}

export async function fetchTaggedResponses(url, params) {
  let response = await API.get(url, params);
  if (response.success) {
    console.log(">>fetchTaggedResponses>>>>>", response);

    return response.success.data;
  } else {
    console.log(response.error);
  }
}

export async function fetchPaginatedResponses(url) {
  //let pagesRemaining = true;
  let response = await API.get(url);
  if (response.success) {
    console.log(">>fetchPaginatedResponses>>>>>", response);

    return response.success.data;
  } else {
    console.log(response.error);
    return response.error;
  }
}

export async function fetchPaginatedAttributeValues(url) {
  //let pagesRemaining = true;
  let response = await API.get(url);
  if (response.success) {
    return response.success.data;
  } else {
    console.log(response.error);
    return response.error;
  }
}

export async function fetchSurveyQuestionResponseData(
  surveyId,
  questionId,
  dateRange,
  interval,
  questionType,
  searchParams
) {
  let response = await API.get(API_URLS.GET_QUESTION_RESPONSES(surveyId, questionId), {
    qtype: questionType,
    interval: interval,
    start_date: dateRange[0],
    end_date: dateRange[1],
    ...(searchParams.get("filterby_response") ? { filterby_response: searchParams.get("filterby_response") } : {}),
    ...(searchParams.get("filterby_country") ? { filterby_country: searchParams.get("filterby_country") } : {}),
    ...(searchParams.get("filterby_attribute") ? { filterby_attribute: searchParams.get("filterby_attribute") } : {}),

    // ...(responseFilterParams.length > 0 ? { filterby_response: responseFilterParams.join() } : {}),
  });
  if (response.success) {
    console.log(">>>NPS ChartData>>>>>", response);

    return response.success.data;
  } else if (response.error) {
    console.log(response.error);
    return response.error.data;
  }
}

export async function fetchPaginatedQuestionResponseData(url, params) {
  let response = await API.get(url, params);
  if (response.success) {
    return response.success.data;
  } else if (response.error) {
    console.log(response.error);
    return response.error.data;
  }
}

export async function putSurvey(requestData, csrfToken, id, activate) {
  let body = JSON.stringify(requestData);
  console.log("in put function", body);

  let response = await APIBase.put(API_URLS.UPDATE_SURVEY(id), body, {
    headers: { "X-CSRFTOKEN": csrfToken },
    params: {
      activate: activate,
    },
  });
  if (response.status !== 201) {
    return response.data;
  }
}

export async function patchSurvey(requestData, csrfToken, id) {
  let body = JSON.stringify(requestData);
  console.log("in patch function", body);

  let response = await APIBase.patch(API_URLS.UPDATE_SURVEY(id), body, {
    headers: { "X-CSRFTOKEN": csrfToken },
  });
  if (response.status !== 201) {
    return response.data;
  }
}

export async function getSurvey() {
  try {
    //console.log('inside get survey');
    const response = await APIBase.get(API_URLS.SURVEYS);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getSurveyWithSummary(currentProductToken) {
  try {
    console.log("inside get survey");
    const response = await APIBase.get(API_URLS.GET_SURVEYS_WITH_SUMMARY(currentProductToken));
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

// export async function getQuestionResponses(surveyId) {
//   try {
//     //console.log('inside get survey');
//     const response = await APIBase.get(API_URLS.GET_AGGREGATE_RESPONSES(surveyId));
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// }

export async function getSurveyById(id) {
  const response = await API.get(API_URLS.GET_SURVEY_BY_ID(id));
  if (response.success) {
    return response.success.data;
  } else {
    console.log(response.error);
  }
}

export async function exportDataAsCSV(survey_id, dateRange, searchParams) {
  const response = await API.get(
    API_URLS.EXPORT_DATA_AS_CSV(survey_id),
    dateRange.every(d => d !== null) && {
      start_date: dateRange[0],
      end_date: dateRange[1],
      ...(searchParams.get("filterby_response") && {
        filterby_response: searchParams.get("filterby_response"),
      }),
      ...(searchParams.get("filterby_country") && {
        filterby_country: searchParams.get("filterby_country"),
      }),
      ...(searchParams.get("filterby_attribute") && {
        filterby_attribute: searchParams.get("filterby_attribute"),
      }),
    }
  );
  if (response.success) {
    exportData(response.success.data, `feedbackspark_survey_responses_${Date.now()}.csv`, "text/csv;charset=utf-8;");
  } else {
    console.log(response.error);
  }
}

// export async function getSurveyResponsesById(id) {
//   try {
//     //console.log('inside get survey');
//     const response = await APIBase.get(API_URLS.GET_SURVEY_RESPONSES(id));
//     //console.log('responses are', response);
//     return response.data;
//   } catch (error) {
//     console.log(error);
//   }
// }
