import React, { useEffect } from 'react';
import { Group, Text, Select, Space, ActionIcon, NumberInput, TextInput, Grid } from '@mantine/core';
import { StyledSelect } from '../../../../components/MantineOverride';
import { DatePicker } from '@mantine/dates';
import { IconTrash } from '@tabler/icons-react';
import { getUserAttributes } from './../../../../api/Account';
import { useAPICall } from './../../../../hooks/useAPICall';
import checkDataType from './../../../../utils/checkDataType';
import AttributeSelection from './../AttributeSelection';

//import API from '../../../api/API';

const attribDataType = {
  number: 1,
  string: 2,
  date: 3,
  boolean: 4,
};

const logicOptions = [
  { value: 'gt', label: 'is greater than', datatype: [1, 3] },
  { value: 'gte', label: 'is greater than or equal to', datatype: [1, 3] },
  { value: 'lt', label: 'is less than', datatype: [1, 3] },
  { value: 'lte', label: 'is less than or equal to', datatype: [1, 3] },
  { value: 'eq', label: 'is equal to', datatype: [1, 2, 3, 4] },
  { value: 'neq', label: 'is not equal to', datatype: [1, 2, 3, 4] },
  { value: 'con', label: 'contains', datatype: [2] },
  { value: 'dcon', label: 'does not contain', datatype: [2] },
  //{ value: 'skip', label: 'is skipped', qType: [1, 2, 3, 4, 5] },
];

export function AttributeFilter(props) {
  const { idx, token, dispatch, filter, filterCount } = props;
  const [attribs, getAttribs] = useAPICall(getUserAttributes, token);
  const { data, error, loading } = attribs;

  useEffect(() => {
    console.log(typeof getAttribs);
    getAttribs();
  }, []);

  function handleFilterRemoval(idx) {
    dispatch({
      type: 'removeFilter',
      filterIndex: idx,
    });
  }
  function filteredLogicOptions(options, datatype) {
    return options.filter(x => x.datatype.find(y => y === attribDataType[datatype]));
  }

  const attributeList = () => {
    console.log('--------->attrs--------->', data);
    const attrList = Object.keys(data);
    return attrList.map(a => ({
      value: a,
      label: a,
      data: data[a].value.toString(),
      datatype: checkDataType(data[a].value),
    }));
  };

  // function handleChange(idx, section, val) {
  //   dispatch({
  //     type: 'updateFilter',
  //     idx: idx,
  //     section: section,
  //     value: val,
  //   });
  // }

  function handleChange(idx, section, val, datatype) {
    dispatch({
      type: 'updateFilter',
      idx: idx,
      section: section,
      value: val,
      ...(section === 'key' && {
        datatype: datatype,
      }),
    });
  }

  function renderPredicate(datatype) {
    switch (datatype) {
      case 'number':
        return <NumberInput value={filter.predicate} onChange={val => handleChange(idx, 'predicate', val, 'number')} />;

      case 'string':
        return (
          <TextInput
            value={filter.predicate}
            onChange={e => handleChange(idx, 'predicate', e.target.value, 'string')}
          />
        );

      case 'boolean':
        return (
          <Select
            placeholder="Select"
            value={filter.predicate}
            onChange={val => handleChange(idx, 'predicate', val, 'boolean')}
            data={[
              { value: true, label: 'True' },
              { value: false, label: 'False' },
            ]}
          />
        );

      case 'date':
        return (
          <DatePicker
            placeholder="Pick Date"
            value={filter.predicate}
            inputFormat="MMM D, YYYY"
            // labelFormat="MM/YYYY"
            onChange={val => handleChange(idx, 'predicate', val, 'date')}
          />
        );

      default:
        return <TextInput value={filter.predicate} onChange={e => handleChange(idx, 'predicate', e.target.value)} />;
    }
  }

  if (loading) {
    return <div>loading....</div>;
  } else if (Object.keys(filter).length === 0) {
    return null;
  } else {
    console.log('Attribs in AttributeFilter', attribs);
    return (
      <>
        {idx > 0 ? (
          <Grid>
            <Grid.Col span={12}>
              <Text size="14px" color="dimmed">
                and
              </Text>
            </Grid.Col>
          </Grid>
        ) : null}
        <Grid>
          <Grid.Col span={3}>
            <AttributeSelection
              attributeList={attributeList}
              idx={idx}
              handleChange={handleChange}
              filter={filter}
              filterCount={filterCount}
            />
          </Grid.Col>
          <Grid.Col span={5}>
            {filter.key && filter.key.length > 0 ? (
              <StyledSelect
                placeholder="Select"
                value={filter.operator}
                onChange={val => handleChange(idx, 'operator', val)}
                data={filteredLogicOptions(logicOptions, attributeList().find(a => a.value === filter.key)?.datatype)}
              />
            ) : null}
          </Grid.Col>
          <Grid.Col span={3}>
            {filter.operator.length > 0
              ? renderPredicate(attributeList().find(a => a.value === filter.key)?.datatype)
              : null}
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon>
              <IconTrash size={18} onClick={() => handleFilterRemoval(idx)} />
            </ActionIcon>
          </Grid.Col>
        </Grid>

        {/* <Group position="left">
          {console.log('AttributeList is', attributeList())}
          <AttributeSelection
            attributeList={attributeList}
            idx={idx}
            handleChange={handleChange}
            filter={filter}
            filterCount={filterCount}
          />

          {console.log('Filter is ', filter)}
          {filter.key && filter.key.length > 0 ? (
            <Select
              placeholder="Select"
              value={filter.operator}
              onChange={val => handleChange(idx, 'operator', val)}
              data={filteredLogicOptions(logicOptions, attributeList().find(a => a.value === filter.key)?.datatype)}
            />
          ) : null}
          {filter.operator.length > 0
            ? renderPredicate(attributeList().find(a => a.value === filter.key)?.datatype)
            : null}

          <ActionIcon>
            <IconTrash size={18} onClick={() => handleFilterRemoval(idx)} />
          </ActionIcon>
        </Group> */}

        <Space h="sm" />
      </>
    );
  }
}
!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="5a9412e3-a6ab-5834-8ea2-aad894332c53")}catch(e){}}();
//# debugId=5a9412e3-a6ab-5834-8ea2-aad894332c53
