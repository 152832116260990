import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "../../../../context/AuthContext";
import {
  Card,
  Group,
  Button,
  Space,
  Divider,
  ActionIcon,
  Alert,
  Accordion,
  Badge,
  Text,
  Box,
  Stack,
} from "@mantine/core";

import { OrderLogic } from ".";
import { Rating, MultipleChoiceQuestion, OpenText, BinaryQuestion, EmailQuestion } from "./../question_types";
import { QuestionChangeMenu } from ".";
import { IsRequired } from "./../index";
import {
  IconGripVertical,
  IconEdit,
  IconClipboardCheck,
  IconClipboardList,
  Icon123,
  IconArrowsLeftRight,
  IconExternalLink,
  IconMoodHappy,
  IconAt,
} from "@tabler/icons-react";
import style from "./../../../../style.scss";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { Redirect } from "../question_types/Redirect";
import { useIntl } from "react-intl";
import { DeleteIcon } from "../../../../components/UI";
import { showIntroduction } from "../../../../utils/helpers";

export function Questions(props) {
  const { dispatch, formState, handleChange, setCurrentQuestion, accordionItem, setAccordionItem, setOpenIntro } =
    props;
  const intl = useIntl();
  const { currentProduct } = useAuthState();
  const [showLogicAlert, setShowLogicAlert] = useState(false);
  const firstRender = useRef(true);

  function handleAccordionChange(e) {
    setAccordionItem(e);
    if (e) {
      setCurrentQuestion(e.split("")[1]);
      setOpenIntro(false);
    } else {
      if (showIntroduction(formState)) {
        setCurrentQuestion(null);
        setOpenIntro(true);
      } else {
        setCurrentQuestion("0");
      }
    }
  }

  useEffect(() => {
    if (firstRender.current) {
      console.log("#987 firstRender.current 1", firstRender.current);
      firstRender.current = false;
      if (showIntroduction(formState)) {
        console.log("#987.1 firstRender.current", firstRender.current);
        setAccordionItem(null);
        setCurrentQuestion(null);
        setOpenIntro(true);
      } else {
        console.log("#987.2 firstRender.current", firstRender.current);
        setAccordionItem("q0");
        setCurrentQuestion(0);
      }
      return;
    }
  }, []);

  useEffect(() => {
    if (showIntroduction(formState) && formState.questions.length === 0) {
      setAccordionItem(null);
      setCurrentQuestion(null);
    } else if (showIntroduction(formState) && formState.questions.length > 0) {
      setAccordionItem(`q${formState.questions.length - 1}`);
      setCurrentQuestion(formState.questions.length - 1);
      setOpenIntro(false);
    } else if (formState.questions.length > 0 && !showIntroduction(formState)) {
      setAccordionItem(`q${formState.questions.length - 1}`);
      setCurrentQuestion(formState.questions.length - 1);
    }
  }, [formState.questions.length]);

  // useEffect(() => {
  //   if (firstRender.current) {
  //     console.log("#987 firstRender.current 1", firstRender.current);

  //     if (showIntroduction(formState)) {
  //       console.log("#987.1 introduction", formState.surveyOptions.introduction);
  //       setAccordionItem(null);
  //       setCurrentQuestion(null);
  //     } else {
  //       console.log("#987.2 introduction", formState.surveyOptions.introduction);
  //       setAccordionItem("q0");
  //       setCurrentQuestion("0");
  //     }
  //   } else {
  //     console.log("firstRender.current 2", firstRender.current);
  //     setAccordionItem(`q${formState.questions.length - 1}`);
  //     setCurrentQuestion(formState.questions.length - 1);
  //   }
  // }, [formState.questions]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function addLogic(e, idx) {
    e.preventDefault();
    dispatch({
      type: "addLogic",
      productName: currentProduct.name,
      index: idx,
    });
  }

  const questionIcon = questionType => {
    switch (questionType) {
      case 1:
        return (
          <Badge radius="sm">
            <Group spacing={2} grow>
              <IconEdit size={14} /> {intl.formatMessage({ id: "survey.open_text", defaultMessage: "Open Text" })}
            </Group>
          </Badge>
        );
      case 2:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <IconClipboardCheck size={14} /> {intl.formatMessage({ id: "survey.mcq", defaultMessage: "MCQ" })}
            </Group>
          </Badge>
        );
      case 3:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <IconClipboardList size={14} /> {intl.formatMessage({ id: "survey.mcq", defaultMessage: "MCQ" })}
            </Group>
          </Badge>
        );
      case 4:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <IconMoodHappy size={14} /> {intl.formatMessage({ id: "survey.nps", defaultMessage: "NPS" })}
            </Group>
          </Badge>
        );
      case 5:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <Icon123 size={14} />
              {intl.formatMessage({ id: "survey.rating", defaultMessage: "Rating" })}
            </Group>
          </Badge>
        );
      case 6:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <IconArrowsLeftRight size={14} /> Binary
            </Group>
          </Badge>
        );
      case 12:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <IconAt size={14} /> {intl.formatMessage({ id: "survey.q_email", defaultMessage: "Email" })}
            </Group>
          </Badge>
        );
      case 30:
        return (
          <Badge radius="sm">
            <Group spacing={2}>
              <IconExternalLink size={14} />{" "}
              {intl.formatMessage({ id: "survey.q_redirect", defaultMessage: "Redirect" })}
            </Group>
          </Badge>
        );
      default:
        return null;
    }
  };

  function handleDragEnd(result) {
    console.log("result", result);
    const logic = formState.questions.find(q => q.options.nextConditions && q.options.nextConditions.length > 0);
    console.log("result logic", logic);
    if (logic) {
      console.log("result: will set logic alert");
      setShowLogicAlert(true);
      // return;
    }
    let qs = reorder(formState.questions, result.source.index, result.destination.index);
    qs.map((q, idx) => (q.order = idx));

    dispatch({
      type: "updateQuestions",
      value: qs,
    });
  }

  function handleRemove(e, idx) {
    e.stopPropagation();
    e.preventDefault();
    console.log("Delete Question:", e);

    dispatch({
      type: "removeQuestion",
      index: idx,
    });
    formState.questions.filter(q => q.order > idx).map(q => (q.order = q.order - 1));
  }

  function renderQuestion(question, idx) {
    console.log("question in render is ", question);

    const props = {
      idx: idx,
      key: `q${idx}`,
      question: question,
      dispatch: dispatch,
      handleChange: handleChange,
      handleRemove: handleRemove,
      totalQuestionCount: formState.questions.length,
      surveyState: formState.state,
      platform: formState.platform,
      intt: intl,
    };

    switch (question.questionType) {
      case 1:
        return <OpenText {...props} />;
      case 2:
        return <MultipleChoiceQuestion {...props} />;
      case 3:
        return <MultipleChoiceQuestion {...props} />;
      case 4:
        return <Rating {...props} />;
      case 5:
        return <Rating {...props} />;
      case 6:
        return <BinaryQuestion {...props} />;
      case 12:
        return <EmailQuestion {...props} />;
      case 30:
        return <Redirect {...props} />;
      default:
        return null;
    }
  }

  const questions = formState.questions.map((question, idx) => {
    return (
      <div key={`q${idx}`}>
        <Draggable
          key={question.questionClientId ? question.questionClientId : `q${idx}`}
          index={idx}
          draggableId={question.questionClientId ? question.questionClientId : `q${idx}`}
        >
          {(provided, snapshot) => (
            <Card
              sx={{ overflow: "visible" }}
              shadow="sm"
              p="lg"
              radius="md"
              mt="md"
              mb="xs"
              withBorder
              className={`${snapshot.isDragging ? style.itemDragging : null}`}
              {...provided.draggableProps}
              ref={provided.innerRef}
            >
              {console.log("#768 accordionItem", accordionItem)}
              {console.log("#16 idx", idx)}
              <Accordion.Item value={`q${idx}`}>
                <Accordion.Control>
                  {accordionItem === `q${idx}` ? (
                    <Group position="apart">
                      <Group>
                        <div {...provided.dragHandleProps} className={style.dragHandle}>
                          <IconGripVertical style={{ width: "18px", height: "18px" }} stroke={1.5} />
                        </div>
                        <QuestionChangeMenu
                          dispatch={dispatch}
                          idx={idx}
                          platform={formState.platform}
                          questions={formState.questions}
                          currentQuestion={question}
                          productName={currentProduct.name}
                          surveyState={formState.state}
                          questionType={question.questionType}
                          intl={intl}
                        />
                      </Group>

                      <Group onClick={e => e.stopPropagation()}>
                        <IsRequired question={question} dispatch={dispatch} idx={idx} intl={intl} />
                        {formState.state != 1 && <DeleteIcon handleClick={e => handleRemove(e, idx)} />}
                      </Group>
                    </Group>
                  ) : (
                    <Stack>
                      <Group>
                        <div {...provided.dragHandleProps} className={style.dragHandle}>
                          <IconGripVertical style={{ width: "18px", height: "18px" }} stroke={1.5} />
                        </div>
                        <Text shadow="xs" size={14}>
                          {idx + 1}
                        </Text>

                        {questionIcon(question.questionType)}
                      </Group>

                      <Group>
                        <Text size={16}>
                          {question.questionType === 30 ? question.options.primaryButtonText : question.questionText}
                        </Text>
                      </Group>
                    </Stack>
                  )}
                </Accordion.Control>
                <Accordion.Panel>
                  <Space h="md" />
                  {renderQuestion(question, idx)}
                  <Space h="sm" />
                  <Divider my="sm" variant="dotted" />
                  {/* {question.options.nextConditions && <Text color="teal">Flow Logic</Text>} */}
                  <ul className={style.ulList}>
                    {question.options.nextConditions &&
                      question.options.nextConditions.map((logic, logicIndex) => {
                        console.log("Logic index in Question Page is", logicIndex);
                        return (
                          <>
                            <OrderLogic
                              qidx={idx}
                              key={"l" + logicIndex}
                              logic={logic}
                              question={question}
                              dispatch={dispatch}
                              logicIndex={logicIndex}
                              questions={formState.questions}
                              totalCount={formState.questions.length}
                            />
                          </>
                        );
                      })}
                    {question.options.nextConditions && question.options.nextConditions.length > 0 && (
                      <li>
                        <p>
                          <Text size={14}>
                            {question.order + 1 == formState.questions.length
                              ? intl.formatMessage({
                                  id: "survey.logic_next_thankyou",
                                  defaultMessage: "Any other response continue to Thank you.",
                                })
                              : intl.formatMessage({
                                  id: "survey.logic_next_question",
                                  defaultMessage: "Any other response continue to next question.",
                                })}
                          </Text>{" "}
                        </p>
                      </li>
                    )}
                  </ul>

                  <Space h={20} />

                  {question.questionType !== 30 && (
                    <Button variant="outline" type="button" onClick={e => addLogic(e, idx)} className="space10">
                      {intl.formatMessage({ id: "survey.btn_add_logic", defaultMessage: "Add Logic" })}
                    </Button>
                  )}
                </Accordion.Panel>
              </Accordion.Item>
            </Card>
          )}
        </Draggable>
      </div>
    );
  });

  return (
    <>
      {showLogicAlert && (
        <Alert title="Heads up" color="red" withCloseButton onClose={() => setShowLogicAlert(false)}>
          {intl.formatMessage({
            id: "survey.logic_alert",
            defaultMessage: "Some of the questions have skip logic conditions. Moving may cause logical error.",
          })}
        </Alert>
      )}

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="dnd-list" direction="vertical">
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <Accordion
                variant="default"
                value={accordionItem}
                onChange={e => handleAccordionChange(e)}
                transitionDuration={400}
                //chevronPosition="left"

                styles={{
                  item: {
                    // styles added to all items
                    backgroundColor: "#fff",
                    border: "none",

                    // styles added to expanded item
                    "&[data-active]": {
                      backgroundColor: "#fff",
                    },
                  },
                  control: {
                    padding: "0px",

                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  },
                }}
              >
                {console.log("showLogicAlert", showLogicAlert)}

                {questions}
              </Accordion>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}
