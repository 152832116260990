import React, { useEffect, useState } from "react";
import { useAuthState } from "../../context/AuthContext";
import { Radio, Input, Select, Group, Anchor, NumberInput, Menu, Text, Card, TextInput } from "@mantine/core";
import { Calendar, RangeCalendar } from "@mantine/dates";
import { pluralize, getLastNDiffDates } from "../../utils/helpers";
import style from "./../../style.scss";
import dayjs from "dayjs";
import checkDataType from "../../utils/checkDataType";
import { IconChevronDown } from "@tabler/icons-react";
import StringValueSelector from "./StringValueSelector";

export default function ValueSelector(props) {
  const { idx, filterObject, handleChange, dispatch, attributeValues, source } = props;
  const [numValue, setNumValue] = useState();
  const [timeNumber, setTimeNumber] = useState(7);
  const [timeUnit, setTimeUnit] = useState("day");
  const [rangeValue, setRangeValue] = useState(getLastNDiffDates());

  const [lastNRangeCalendarOpen, setLastNRangeCalendarOpen] = useState(false);
  const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
  const [dateCalendarOpen, setDateCalendarOpen] = useState(false);
  const [dateValue, setDateValue] = useState();
  const operator = filterObject.operator;
  const datatype = filterObject.datatype;

  const { currentProduct } = useAuthState();
  const userTimezone = currentProduct.timezone || "UTC";

  function handleNumSubmit(e) {
    e.preventDefault();

    if (source === "filter") {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: "predicate",
        value: numValue,
      });
    } else {
      dispatch({
        type: "updateFilter",
        idx: idx,
        section: "predicate",
        value: numValue,
      });
    }
  }

  useEffect(() => {
    if (filterObject.datatype === "date") {
      if (source === "survey") {
        setRangeValue(getLastNDiffDates());
        setTimeNumber(7);
        setTimeUnit("day");
      }
    }
  }, [filterObject.operator]);

  function handleTimeNumberChange(e) {
    console.log("handleDaysCountChange", e.target.value);
    setTimeNumber(e.target.value);
    setRangeValue(getLastNDiffDates(e.target.value, timeUnit));
  }
  function handleTimeUnitChange(val) {
    setTimeUnit(val);
    setRangeValue(getLastNDiffDates(timeNumber, val));
  }

  function handleCalendarChange(val) {
    console.log("#997 calendarChange Val", val);
    setRangeValue(val);
    if (val[1] === null) {
      setTimeNumber(dayjs(val[0]).diff(dayjs(val[0]), "day"));
    } else {
      setTimeNumber(dayjs(val[1]).diff(dayjs(val[0]), "day"));
    }
    if (source === "filter") {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: "predicate",
        value: [dayjs(val[0]).tz(userTimezone).unix(), dayjs(val[1]).tz(userTimezone).unix()],
      });
    } else {
      dispatch({
        type: "updateFilter",
        idx: idx,
        section: "predicate",
        value: `${timeNumber}-${timeUnit}`,
      });
    }
  }

  function handleApply(val, section) {
    if (source === "filter") {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: section,
        value: val,
      });
    } else {
      dispatch({
        type: "updateFilter",
        idx: idx,
        section: section,
        value: val,
      });
    }
  }

  if (operator) {
    if (datatype === "boolean") {
      return (
        <Radio.Group
          value={filterObject.predicate}
          onChange={val => handleChange(idx, "predicate", val)}
          name="Boolean Attribute"
        >
          <Radio value="true" label="True" />
          <Radio value="false" label="False" />
        </Radio.Group>
      );
    } else if (datatype === "number") {
      return (
        <form onSubmit={e => handleNumSubmit(e)}>
          <NumberInput
            placeholder="Enter value"
            hideControls
            value={filterObject.predicate}
            onChange={val => handleApply(val, "predicate")}
          />
        </form>
      );
    } else if (datatype === "date") {
      switch (operator) {
        case "last":
        case "notinlast":
          return (
            <>
              <Menu
                opened={lastNRangeCalendarOpen}
                onChange={setLastNRangeCalendarOpen}
                position="bottom-start"
                shadow="sm"
              >
                <Menu.Target>
                  <Card withBorder radius="xs" px={10} py={6}>
                    <Group>
                      <Text className={style.filterHighlighter} size={14}>
                        {filterObject.predicate && checkDataType(filterObject.predicate) === "string" ? (
                          pluralize(filterObject.predicate.split("-")[0], filterObject.predicate.split("-")[1], "s")
                        ) : (
                          <Text color="dimmed">Select duration</Text>
                        )}
                      </Text>
                      <IconChevronDown size={18} color="rgb(134, 142, 150)" />
                    </Group>
                  </Card>
                </Menu.Target>
                {console.log("#997 filterObject.predicate", filterObject.predicate)}

                <Menu.Dropdown className={style.filterDropdown}>
                  <Group>
                    <Input sx={{ width: "120px" }} value={timeNumber} onChange={val => handleTimeNumberChange(val)} />
                    <Select
                      sx={{ width: "120px" }}
                      value={timeUnit}
                      onChange={val => handleTimeUnitChange(val)}
                      data={[
                        { value: "day", label: timeNumber > 1 ? "Days" : "Day" },
                        { value: "week", label: timeNumber > 1 ? "Weeks" : "Week" },
                        { value: "month", label: timeNumber > 1 ? "Months" : "month" },
                      ]}
                    />
                  </Group>

                  <RangeCalendar
                    value={rangeValue}
                    range={rangeValue}
                    allowLevelChange={false}
                    focusable={true}
                    allowSingleDateInRange={false}
                    onChange={handleCalendarChange}
                    maxDate={new Date()}
                    //minDate={new Date(2023, 8, 1)}
                  />
                  <Group position="apart" mt={20}>
                    <Anchor color="dimmed" onClick={() => setLastNRangeCalendarOpen(false)}>
                      Close
                    </Anchor>
                    <Anchor
                      onClick={() => {
                        handleCalendarChange(rangeValue);
                        setLastNRangeCalendarOpen(false);
                      }}
                    >
                      Apply
                    </Anchor>
                  </Group>

                  {/* <DateRangePicker placeholder="Pick dates range" value={dateValue} onChange={setDateValue} /> */}
                </Menu.Dropdown>
              </Menu>
              <Card>{console.log("RangeValue", rangeValue)}</Card>
            </>
          );
        case "between":
          return (
            <Menu
              opened={rangeCalendarOpen}
              onChange={setRangeCalendarOpen}
              withinPortal
              position="bottom-end"
              shadow="sm"
            >
              <Menu.Target>
                <Card withBorder radius="xs" px={10} py={6}>
                  <Group>
                    <Text className={style.filterHighlighter} size={14}>
                      {filterObject.predicate[0]
                        ? dayjs.unix(filterObject.predicate[0]).format("MMM D, YY")
                        : "Start Date"}{" "}
                      -{" "}
                      {filterObject.predicate[1]
                        ? dayjs.unix(filterObject.predicate[1]).format("MMM D, YY")
                        : " End Date"}
                    </Text>
                    <IconChevronDown size={16} color="rgb(134, 142, 150)" />
                  </Group>
                </Card>
              </Menu.Target>

              <Menu.Dropdown p={20}>
                <RangeCalendar
                  value={rangeValue}
                  allowLevelChange={false}
                  allowSingleDateInRange={true}
                  onChange={setRangeValue}
                  maxDate={new Date()}
                />
                <Group position="apart" mt={20} mb={10}>
                  <Anchor color="dimmed" onClick={() => setRangeCalendarOpen(false)}>
                    Close
                  </Anchor>
                  <Anchor
                    onClick={() => {
                      handleApply(
                        [dayjs(rangeValue[0]).tz(userTimezone).unix(), dayjs(rangeValue[1]).tz(userTimezone).unix()],
                        "predicate"
                      );
                      setRangeCalendarOpen(false);
                    }}
                  >
                    Apply
                  </Anchor>
                </Group>

                {/* <DateRangePicker placeholder="Pick dates range" value={dateValue} onChange={setDateValue} /> */}
              </Menu.Dropdown>
            </Menu>
          );
        case "on":
        case "noton":
        case "before":
        case "since":
          return (
            <Menu
              opened={dateCalendarOpen}
              onChange={setDateCalendarOpen}
              withinPortal
              position="bottom-end"
              shadow="sm"
            >
              <Menu.Target>
                <Card withBorder radius="xs" px={10} py={6}>
                  <Group>
                    <Text className={style.filterHighlighter} size={14}>
                      {filterObject.predicate.toString().length > 0 && dayjs.unix(filterObject.predicate).isValid() ? (
                        dayjs.unix(filterObject.predicate).format("MMM D, YY")
                      ) : (
                        <Text color="dimmed">Select Date</Text>
                      )}
                    </Text>
                    <IconChevronDown size={16} color="rgb(134, 142, 150)" />
                  </Group>
                </Card>
              </Menu.Target>

              <Menu.Dropdown p={20}>
                <Calendar value={dateValue} onChange={setDateValue} />
                <Group position="apart" mt={20} mb={10}>
                  <Anchor color="dimmed" onClick={() => setDateCalendarOpen(false)}>
                    Close
                  </Anchor>
                  <Anchor
                    onClick={() => {
                      handleApply(dayjs(dateValue).tz(userTimezone).unix(), "predicate");
                      setDateCalendarOpen(false);
                    }}
                  >
                    Apply
                  </Anchor>
                </Group>
              </Menu.Dropdown>
            </Menu>
          );

        default:
          break;
      }
    } else {
      if (source === "filter") {
        return (
          <TextInput
            value={filterObject.predicate}
            placeholder="Enter value"
            onChange={e => handleChange(idx, "predicate", e.target.value)}
          />
        );
      } else {
        if (filterObject.operator === "set" || filterObject.operator === "notset") {
          return null;
        } else if (filterObject.operator === "con" || filterObject.operator === "dcon") {
          return (
            <TextInput
              placeholder="Enter value"
              value={filterObject.predicate}
              onChange={e => handleChange(idx, "predicate", e.target.value, "string")}
            />
          );
        } else {
          if (attributeValues && attributeValues.length > 1 && attributeValues.length < 10) {
            console.log("@king ppppppppppppppp", props);
            return (
              <StringValueSelector
                attributeValues={attributeValues}
                dispatch={dispatch}
                filterObject={filterObject}
                idx={idx}
                source={source}
              />
            );
          } else
            return (
              <TextInput
                placeholder="Enter value"
                value={filterObject.predicate}
                onChange={e => handleChange(idx, "predicate", e.target.value, "string")}
              />
            );
        }
      }
    }
  }
}
