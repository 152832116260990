import React, { useState, useEffect, useRef } from "react";
import { Modal, Table, Text, ThemeIcon, useMantineTheme, Loader, Center, Group, Box, Space } from "@mantine/core";

import { fetchPaginatedResponses, fetchTaggedResponses } from "../../../../../api/Survey";
import { capitalize } from "../../../../../utils/helpers";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../../../../components/ErrorFallback";
import { useIntl } from "react-intl";
import * as Sentry from "@sentry/react";

export default function VerbatimModal(props) {
  const { opened, setOpened, page1_url, duration, clickCount, tagType, tagValue, setSearchParams, searchParams } =
    props;
  const [verbatimData, setVerbatimData] = useState([]);

  const intl = useIntl();

  // const [opened, setOpened] = useState(false);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState();
  const [nextURL, setNextURL] = useState();
  const theme = useMantineTheme();

  const endRef = useRef(null);

  console.log("verbatim Modal", searchParams);

  // const respondentId = searchParams && searchParams.get("respondent") && searchParams.get("respondent").split("/")[0];

  useEffect(() => {
    setVerbatimData([]);
    fetchTaggedResponses(page1_url, {
      duration: duration,
      verbatim: tagType,
      value: tagValue,
      ...(searchParams.get("filterby_response") && { filterby_response: searchParams.get("filterby_response") }),
      ...(searchParams.get("filterby_country") && { filterby_country: searchParams.get("filterby_country") }),
    })
      .then(data => {
        setVerbatimData(data.results);
        setNextURL(data.next);
      })
      .catch(error => {
        setError(error);
      })
      .finally(setLoader(false));
  }, [clickCount]);

  // useEffect(() => {
  //   if (respondentId) {
  //     API.get(`surveys/${surveyId}/respondents/${respondentId}/responses/`).then(response => {
  //       if (response.success) {
  //         console.log("@respondent", response.success.data);
  //         setUserResponses(response.success.data);
  //       } else {
  //         //showErrorNotification(response.error);
  //         console.error(response.error);
  //       }
  //     });
  //   }
  // }, [respondentId]);

  useEffect(() => {}, [verbatimData]);

  const sentimentIcon = sentiment => {
    switch (sentiment) {
      case "Positive":
        return <ThemeIcon radius="lg" size="sm" color="#16DB93" />;
      case "Negative":
        return <ThemeIcon radius="lg" size="sm" color="#F64740" />;
      case "Neutral":
        return <ThemeIcon radius="lg" size="sm" color="#FFE74C" />;
      case "Mixed":
        return <ThemeIcon radius="lg" size="sm" color="indigo" />;
      default:
        break;
    }
  };

  console.log("VERBATIM MODAL DaTA", verbatimData);

  const verbatimRows =
    verbatimData &&
    verbatimData.length > 0 &&
    verbatimData.map((d, idx) => (
      <tr key={idx}>
        <td
          onClick={() => {
            searchParams.append("respondent", d.respondent_id);
            setSearchParams(searchParams);
          }}
          style={{ width: "15%" }}
        >
          <Text sx={{ cursor: "pointer" }} color={theme.primaryColor}>
            {d.respondent_id}
          </Text>
        </td>
        <td style={{ width: "60%" }}>{d.answer}</td>
        <td style={{ width: "20%" }}>
          <Group>
            {sentimentIcon(d.sentiment)}
            <Text>{d.sentiment}</Text>
          </Group>
        </td>
        {/* <td style={{ width: "5%" }}>
          {d.urgent === "Yes" && (
            <HoverCard width={150} shadow="md">
              <HoverCard.Target>
                <ThemeIcon radius="lg" size="sm" color="red">
                  <IconUrgent color="black" />
                </ThemeIcon>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text size="xs">Action Required</Text>
              </HoverCard.Dropdown>
            </HoverCard>
          )}
        </td> */}
      </tr>
    ));

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <Modal
      transition="fade"
      transitionDuration={600}
      transitionTimingFunction="ease"
      overlayColor={theme.colorScheme === "dark" ? theme.colors.dark[9] : theme.colors.gray[2]}
      overlayOpacity={0.55}
      overlayBlur={3}
      centered
      size="75%"
      overflow="inside"
      opened={opened}
      onClose={() => {
        setOpened(false);
        searchParams.delete("theme");
        searchParams && setSearchParams(searchParams);
      }}
      styles={() => ({
        modal: {
          //height: 'calc(100vh - 100px)',
        },
        title: {
          display: "none",
        },
        header: {
          margin: 0,
        },
        close: {
          position: "absolute",
          right: 0,
          outline: "none",
        },
      })}
      //title={tag}
    >
      {error ? (
        <Text>
          {error.message || intl.formatMessage({ id: "common.loading_error", defaultMessage: "Something went wrong." })}
        </Text>
      ) : null}
      {loader ? (
        <Center>
          <Loader />
        </Center>
      ) : verbatimData && verbatimData.length > 0 ? (
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
          onError={error => {
            // log the error to your error reporting service
            Sentry.captureException(error);
          }}
        >
          <Text sx={{ fontWeight: 700 }} align="center">
            {capitalize(tagType)}: {tagValue}
          </Text>
          {verbatimRows ? (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>{intl.formatMessage({ id: "common.user", defaultMessage: "User" })}</th>
                    <th>{intl.formatMessage({ id: "common.response", defaultMessage: "Response" })}</th>
                    <th>{intl.formatMessage({ id: "reports.sentiment", defaultMessage: "Sentiment" })}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{verbatimRows}</tbody>
              </Table>
              <div ref={endRef}></div>
            </>
          ) : (
            <Center>
              <Loader />
            </Center>
          )}

          {verbatimRows && nextURL && (
            <Box sx={{ textAlign: "center" }}>
              <Space h="md" />
              <Text
                onClick={() => {
                  fetchPaginatedResponses(nextURL).then(data => {
                    setVerbatimData([...verbatimData, ...data.results]);

                    setNextURL(data.next);
                  });
                }}
                sx={{ cursor: "pointer", textDecorationLine: "underline" }}
                size="xs"
                color="blue"
              >
                {intl.formatMessage({ id: "common.load_more", defaultMessage: "Load more" })}
              </Text>
            </Box>
          )}
        </ErrorBoundary>
      ) : (
        <Center>
          <Loader />
        </Center>
      )}
    </Modal>
  );
}
