export const embedEmailContent = (firstQuestion, surveyURL, userTag) => {
  console.log("!! firstQuestion", firstQuestion);
  switch (firstQuestion?.question_type) {
    case 4:
      return npsEmailContent(firstQuestion, surveyURL, userTag);
    case 2:
      return mcqSingleChoiceEmailContent(firstQuestion, surveyURL, userTag);

    default:
      break;
  }
};

const npsEmailContent = (firstQuestion, surveyEndpoint, userTag) => {
  const cellColor = "#ffffff";
  const bkgColor = "#ffffff";

  const linkStyle = `text-decoration: none; display: block; margin: 0 auto; background-color: ${cellColor}; color: #333; border-radius: 4px; border: 1px solid #ccc; border-bottom: 3px solid #ccc; text-align: center; font-weight: bold; padding: 6px 0; max-width: 60px;`;
  const cellStyle = `width: 9%; text-align: center; padding: 5px;`;

  const cellHTML = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].flatMap(num => {
    return `<td style="${cellStyle}"><a aria-label="${num}" href="${surveyEndpoint}?resp=${num}${
      userTag.length > 0 ? `&${userTag}` : ""
    }" style="${linkStyle}">${num}</a></td>`;
  });

  const actualHTML = `<table width="100%" cellpadding="0" cellspacing="0" bgcolor="#ffffff" style="max-width: 600px; width: 100%; margin: auto;"><tr><td align="center"><table width="100%" border="0" cellspacing="0" cellpadding="0">
<tr><td align="left" style="background-color: ${bkgColor}; padding: 20px;">
<p style="font-size: 18px; margin: 20px 0;">${firstQuestion ? firstQuestion.question_text : ""}</p>
<table cellspacing="0" cellpadding="0" border="0" style="margin: 0 auto; width: 100%;"><tr>
${cellHTML.join("")}
</tr></table><table border="0" cellspacing="0" cellpadding="0" width="100%"><tr>
<td align="left" style="font-size: 12px;">${firstQuestion ? firstQuestion.options.min_text : ""}</td>
<td align="right" style="font-size: 12px;">${firstQuestion ? firstQuestion.options.max_text : ""}</td>
</tr></table></td></tr></table></td></tr></table>`;

  return actualHTML;
};

const mcqSingleChoiceEmailContent = (firstQuestion, surveyEndpoint, userTag) => {
  if (firstQuestion.choices.length > 2) {
    const choiceHTML = firstQuestion.choices.flatMap(choice => {
      return `<p style="margin-bottom:10px;"><a href="${surveyEndpoint}?resp=${encodeURIComponent(choice.choice)}&cid=${
        choice.id
      }${
        userTag.length > 0 ? `&${userTag}` : ""
      }" style="text-decoration:none; background-color:#fff; padding:10px 15px; display:inline-block; width:100%; max-width:300px; text-align:center;color: #333; border-radius: 4px; border: 1px solid #ccc; border-bottom: 3px solid #ccc;">${
        choice.choice
      }</a></p>`;
    });

    return `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:600px; background-color:#ffffff; margin-top:30px;"><tr><td style="padding:20px; text-align:center;"><p style="font-size:18px; margin-bottom:30px;"><b>${
      firstQuestion.question_text
    }</b></p>${choiceHTML.join("")}</td></tr></table>`;
  } else {
    return `<table width="100%" cellpadding="0" cellspacing="0" border="0" style="font-family: Arial, sans-serif;"><tr>
    <td align="center"><table cellpadding="0" cellspacing="0" border="0" style="background-color: #ffffff; padding: 20px; text-align: center; ">
    <tr><td><p style="font-size: 18px; margin-bottom: 30px;"><b>${
      firstQuestion.question_text
    }</b></p><table cellpadding="0" cellspacing="0" border="0" style="margin: auto;margin-bottom:20px;">
    <tr><td style="padding: 0 10px;"><a href="${surveyEndpoint}?resp=${encodeURIComponent(
      firstQuestion.choices[0].choice
    )}&cid=${firstQuestion.choices[0].id}${
      userTag.length > 0 ? `&${userTag}` : ""
    }" style="background-color: #fff; color: #333; padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;border: 1px solid #ccc; border-bottom: 3px solid #ccc;">${
      firstQuestion.choices[0].choice
    }</a>
    </td><td style="padding: 0 10px;"><a href="${surveyEndpoint}?resp=${encodeURIComponent(
      firstQuestion.choices[1].choice
    )}&cid=${firstQuestion.choices[1].id}${
      userTag.length > 0 ? `&${userTag}` : ""
    }" style="background-color: #fff; color: #333;padding: 10px 20px; text-decoration: none; border-radius: 4px; font-size: 16px;border: 1px solid #ccc; border-bottom: 3px solid #ccc;">${
      firstQuestion.choices[1].choice
    }</a>
    </td></tr></table></td></tr></table></td></tr></table>`;
  }
};
