import React, { useEffect, useState } from "react";
import { Stack, Group, Text, Menu, Flex } from "@mantine/core";
import { getAttributeValues } from "../../../../api/Survey";
import AttributeValueSelector from "./AttributeValueSelector";
import style from "../../../../style.scss";
import { filterComparatorOptions, attributeIcon } from "../../../../utils/filterHelper";
import ValueSelector from "../../../../components/attributes/ValueSelector";

export function FilterAttribute(props) {
  const { f, filterIndex, attributes, dispatch, surveyId, token, csrftoken, searchParams } = props;
  const [opened, setOpened] = useState(searchParams.get("filterby_attribute") ? false : true);
  const [operatorOpened, setOperatorOpened] = useState(false);
  const [attributeValues, setAttributeValues] = useState();
  // [valueOpened, setValueOpened] = useState(false);

  function fetchAttributeValues(attr) {
    getAttributeValues({ attribute: attr }, surveyId, token, csrftoken).then(resp => {
      console.log("getAttributeValues", resp.data.values);
      if (resp.status === 200) {
        setAttributeValues(resp.data.values);
      } else {
        console.log("error");
      }
    });
  }
  useEffect(() => {
    if (f.property) {
      fetchAttributeValues(f.property);
    }
  }, []);

  function handleAttributeSelect(idx, a) {
    console.log("Menu Selected", a);
    dispatch({
      type: "updateFilter",
      filterType: "attributeFilter",
      index: idx,
      section: "property",
      value: a.value,
      datatype: a.type,
    });

    fetchAttributeValues(a.value);

    console.log("attribute values", attributeValues);
  }

  function handleAttributeUpdate(idx, section, value) {
    dispatch({
      type: "updateFilter",
      filterType: "attributeFilter",
      index: idx,
      section: section,
      value: value,
    });
  }

  function selectedOperator(filterObj, idx) {
    console.log("filterObj", filterObj);
    if (filterObj.operator !== "") {
      return filterComparatorOptions[filterObj.datatype].operators.find(o => o.value == filterObj.operator).label;
    } else {
      dispatch({
        type: "updateFilter",
        filterType: "attributeFilter",
        index: idx,
        section: "operator",
        value: filterComparatorOptions[filterObj.datatype].default.value,
      });
      return filterComparatorOptions[filterObj.datatype].default.label;
    }
  }

  function getValueType(attr) {
    const selectedAttribute = attributes.filter(a => a.value === attr);
    console.log(
      "attr",
      attributes.filter(a => a.value === attr)
    );
    if (selectedAttribute.length > 0) {
      return selectedAttribute[0].type;
    }
  }

  return (
    <Stack>
      <Group position="left">
        {attributeIcon(getValueType(f.property))}
        <Group sx={{ flexGrow: 1 }} position="apart">
          <Menu
            sx={{ flexGrow: 1 }}
            position="bottom-start"
            shadow="md"
            width={300}
            opened={opened}
            onChange={setOpened}
          >
            <Menu.Target>
              <Text className={`${style.filterHighlighter} ${opened && style.inputHighlight}`} size={16}>
                {(f && f.property) || "Select Attribute"}
              </Text>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>User Attributes</Menu.Label>
              {attributes.map((a, idx) => {
                return (
                  <Menu.Item key={idx} onClick={() => handleAttributeSelect(filterIndex, a)}>
                    {a.label}
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>
      <Flex
        className={style.attributeOperatorValue}
        gap="md"
        justify="flex-start"
        align="flex-start"
        direction="row"
        wrap="nowrap"
      >
        {f.datatype && (
          <Menu position="bottom-start" shadow="md" width={300} opened={operatorOpened} onChange={setOperatorOpened}>
            <Menu.Target>
              <Text
                className={`${style.filterHighlighter} ${operatorOpened && style.inputHighlight}`}
                size={14}
                sx={{ flexGrow: 0 }}
              >
                {selectedOperator(f, filterIndex)}
              </Text>
            </Menu.Target>

            <Menu.Dropdown>
              {filterComparatorOptions[f.datatype].operators.map((o, idx) => {
                return (
                  <Menu.Item onClick={() => handleAttributeUpdate(filterIndex, "operator", o.value)} key={idx}>
                    {o.label}
                  </Menu.Item>
                );
              })}
            </Menu.Dropdown>
          </Menu>
        )}

        {f.operator && (f.predicate || attributeValues) && (
          <div style={{ flexGrow: 1, flexShrink: 1 }}>
            <AttributeValueSelector
              idx={filterIndex}
              handleChange={handleAttributeUpdate}
              filterObject={f}
              attributeValues={attributeValues}
              dispatch={dispatch}
              //handleSubmit={handleSubmit}
            />
          </div>
        )}
      </Flex>
    </Stack>
  );
}
