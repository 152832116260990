import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  AppShell,
  Navbar,
  Container,
  Text,
  Header,
  MediaQuery,
  Burger,
  Image,
  useMantineTheme,
  Center,
  Button,
  Anchor,
} from "@mantine/core";
//import { useViewportSize } from '@mantine/hooks';
import { IconDeviceDesktopAnalytics } from "@tabler/icons-react";
import style from "../../style.scss";
import NavSidebar from "./NavSidebar";
import { logoutUser } from "../../api/Auth";
import { useAuthState, useAuthDispatch } from "../../context/AuthContext";
//import ProductSelection from '../../components/ProductSelection';
//import ErrorBoundary from '../../components/ErrorBoundary';
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../../components/ErrorFallback";
import * as Sentry from "@sentry/react";

function DashboardLayout() {
  const [opened, setOpened] = useState(false);

  const theme = useMantineTheme();
  const dispatch = useAuthDispatch();
  const { user } = useAuthState();

  //

  console.log("user in DashboardLayout is", user);

  window.addEventListener(
    "orientationchange",
    //updateScreen(screen.availWidth),
    function () {
      window.location.reload();
    },
    false
  );

  if (window.outerWidth < 968) {
    return (
      <Container mt={10}>
        <div style={{ marginBottom: "50px" }}>
          <Center>
            <Image
              placeholder={
                <Text fw={900} fz={32} className={style.logo}>
                  FeedbackSpark
                </Text>
              }
              src="https://cdn.feedbackspark.com/logos/fslogo.svg"
              width={150}
              alt="FeedbackSpark logo"
            />
          </Center>
        </div>
        <Center>
          <IconDeviceDesktopAnalytics size={160} stroke={1} color="gray" />
        </Center>
        <Center>
          <Text align="center">Please use FeedbackSpark on a dekstop for the best experience.</Text>
        </Center>
        <Center>
          <Anchor mt={20} onClick={e => logoutUser(e, dispatch)}>
            Logout
          </Anchor>
        </Center>
      </Container>
    );
  } else {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
        onError={error => {
          // log the error to your error reporting service
          Sentry.captureException(error);
        }}
      >
        <AppShell
          //padding="md"
          //sx={{ paddingTop: '0px' }}
          navbarOffsetBreakpoint="lg"
          navbar={
            <Navbar
              p="md"
              hiddenBreakpoint="lg"
              hidden={!opened}
              width={{ sm: 200, lg: 200, xl: 200, base: 200 }}
              //position={{ top: '50px' }}
            >
              {NavSidebar()}
            </Navbar>
          }
          styles={{
            main: {
              backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0],
              //paddingTop: '50px',
              paddingRight: "50px",
              //paddingRight: 'calc(var(--mantine-aside-width, 200px) + 16px)',
            },
          }}
          header={
            <Header height={{ base: 50, md: 70 }} p="md">
              <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                <MediaQuery largerThan="lg" styles={{ display: "none" }}>
                  <Burger
                    opened={opened}
                    onClick={() => setOpened(o => !o)}
                    size="sm"
                    color={theme.colors.gray[6]}
                    mr="xl"
                  />
                </MediaQuery>

                <Image
                  placeholder={
                    <Text fw={900} fz={32} className={style.logo}>
                      FeedbackSpark
                    </Text>
                  }
                  src="https://cdn.feedbackspark.com/logos/fslogo.svg"
                  width={150}
                  alt="FeedbackSpark logo"
                />
              </div>
            </Header>
          }
        >
          <Outlet />
        </AppShell>
      </ErrorBoundary>
    );
  }
}

export default DashboardLayout;
